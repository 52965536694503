
import {
  FETCH_CITIES
} from '../actions/types';
import cities from '../assets/mocked-json/cities';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case FETCH_CITIES:
        console.log("cities in placesReducer", cities);  
      return [...state, ...cities];
    default:
      return state;
  }
};
