import { Box, Card, Grid, IconButton, Stack, useTheme } from "@mui/material";
import AppPagination from "./wrappers/AppPagination";
import AppAvatar from "./avatars/AppAvatar";
import { H6, Tiny } from "./Typography";
import Person2Icon from '@mui/icons-material/Person2';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useEffect, useState } from "react";
import {connect} from 'react-redux';
import BhaktaPopup from "./Popups/BhaktaPopup";
import { openModal, setFiles, setLinks } from '../actions';

const GridView = ({places, openModal, setFiles, setLinks}) => {
  const theme = useTheme(); // handle change for tab list

  const godImagesMap = {
    "Ganesha": {"cover":"ganesha-cover.png", "logo":"ganesha-logo.png"},
    "Shiva": {"cover":"shiva-cover.png", "logo":"shiva-logo.png"},
    "Vishnu": {"cover":"vishnu-cover.png", "logo":"vishnu-logo.png"},
    "Skanda": {"cover":"skanda-cover.png", "logo":"skanda-logo.png"},
    "Shakti": {"cover":"shakti-cover.png", "logo":"shakti-logo.png"},
  };

  const iconStyle = {
    color: "text.disabled",
    fontSize: 16,
  };
  const handleRowClick = (row) => {
    //setShowModal(true);
    openModal(row);
    if (row.links) {
      const links = row.links.map(link => [link.name, link.url]);
      setLinks(links);
    }
    setFiles(row.photos);
  };
  const placesPerPage = 8;
  const [filteredPlaces, setFilteredPlaces] = useState(places.slice(0, placesPerPage));
  /*useEffect(() => {
    const result = searchByName(USER_LIST, searchValue);
    setFilteredItem(result);
  }, [searchValue]);*/
  const handlePageChange = (_, currentPageNo) => {
    const startIndex = (currentPageNo-1) * placesPerPage;
    setFilteredPlaces(places.slice(startIndex, startIndex + placesPerPage));
  };
  return (
    <Box pt={2} pb={4}>
      <Card
        sx={{
          px: 3,
          py: 2,
        }}
      >

        <Grid container spacing={3}>
          {filteredPlaces.map((item, index) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={index}>
              <Box
                sx={{
                  padding: 2,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: 1,
                }}
                onClick={() => handleRowClick(item)}
              >

                <Stack direction="row" alignItems="center" py={2} spacing={2}>
                  <AppAvatar
                    src={godImagesMap[item.god].logo}
                    sx={{
                      borderRadius: "20%",
                    }}
                  />
                  <Box>
                    <H6>{item.name}</H6>
                    <Tiny color="text.secondary" fontSize={12}>
                      {item.god}
                    </Tiny>
                  </Box>
                </Stack>

                <Stack direction="row" alignItems="center" spacing={1}>
                  <Person2Icon sx={iconStyle} />
                  <Tiny color="text.secondary" fontSize={12}>
                    {item.type}
                  </Tiny>
                </Stack>

                <Stack direction="row" alignItems="center" mt={1} spacing={1}>
                  <LocationOnIcon sx={iconStyle} />
                  <Tiny color="text.secondary" fontSize={12}>
                    {item.city}
                  </Tiny>
                </Stack>

                <Stack direction="row" alignItems="center" mt={1} spacing={1}>
                  <AccessTimeIcon sx={iconStyle} />
                  <Tiny color="text.secondary" fontSize={12}>
                    {item.yuga}
                  </Tiny>
                </Stack>
              </Box>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Stack alignItems="center" py={2}>
              <AppPagination shape="rounded" count={Math.ceil(places.length/placesPerPage)} onChange={handlePageChange}/>
            </Stack>
          </Grid>
          <BhaktaPopup/>
        </Grid>
      </Card>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    places: state.places.activePlaces,
    status: state.status
  };
};

export default connect(mapStateToProps, {openModal, setFiles, setLinks})(GridView);
