import { Box, Button, IconButton, styled } from "@mui/material";
import FlexBetween from "../flexbox/FlexBetween";
import SearchInput from "./SearchInput";
import React from "react";

const SecondaryWrapper = styled(FlexBetween)(({ theme }) => ({
  gap: 8,
  flexWrap: "wrap",
  margin: "24px 0",
  [theme.breakpoints.down(550)]: {
    "& .MuiInputBase-root": {
      maxWidth: "calc(100% - 90px)",
    },
  },
})); // --------------------------------------------------------------------

// --------------------------------------------------------------------
const SearchArea = ({
  value,
  onChange,
  setValue,
  placeholder
}) => {


  return (
    <SecondaryWrapper>
      <SearchInput
        placeholder={placeholder}
        value={value || ""}
        onChange={(e) => {
          if (onChange && setValue) {
            setValue(e.target.value);
            onChange(e.target.value);
          }

          onChange(e.target.value);
        }}
      />

    </SecondaryWrapper>
  );
};

export default SearchArea;
