
import {
    FETCH_FILTER,
    UPDATE_FILTER,
    UPDATE_GOD_FILTER,
    UPDATE_TYPE_FILTER,
    UPDATE_YUGA_FILTER,
    UPDATE_NAME_FILTER,
    UPDATE_CITY_FILTER,
    RESET_FILTER
  } from '../actions/types';
  
  const INITIAL_STATESS = {
    gods:{"ganesha":false, "shiva":false,"vishnu":false,"skanda":false,"shakti":false},
    types:{"paramatma":false, "bhakta":false,"alwar":false,"nayanar":false,"sati":false},
    yugas:{"satya":false, "treta":false,"dwapara":false,"kali":false},
    bhaktaName:"",
    city:""
  };

  const INITIAL_STATE = {
    gods:[{"name":"ganesha", "value":false},{"name":"shiva", "value":false},{"name":"vishnu", "value":false},
    {"name":"skanda", "value":false},{"name":"shakti", "value":false}],
    types:[{"name":"paramatma", "value":false},
    {"name":"bhakta", "value":false},{"name":"alwar", "value":false},{"name":"nayanar", "value":false},
    {"name":"sati", "value":false}],
    yugas:[{"name":"sati", "value":false},{"name":"satya", "value":false},{"name":"treta", "value":false},
    {"name":"dwapara", "value":false},{"name":"kali", "value":false}],
    bhaktaName:"",
    city:""
  };

  const INITIAL_STATES = [
    {"name":"ganesha", "value":false},{"name":"shiva", "value":false},{"name":"vishnu", "value":false},
    {"name":"skanda", "value":false},{"name":"shakti", "value":false},{"name":"paramatma", "value":false},
    {"name":"bhakta", "value":false},{"name":"alwar", "value":false},{"name":"nayanar", "value":false},
    {"name":"sati", "value":false},{"name":"satya", "value":false},{"name":"treta", "value":false},
    {"name":"dwapara", "value":false},{"name":"kali", "value":false},{"name":"bhaktaName", "value":""},
    {"name":"city", "value":""}
  ];

  const resetFilter = (state) => {
    //return {...INITIAL_STATE};
    return {
      gods:state.gods.map(god => {
        return {...god, "value":false};
      }),
      types: state.types.map(type => {
        return {...type, "value": false}
      }),
      yugas: state.yugas.map(yuga => {
        return {...yuga, "value": false}
      }),
      bhaktaName:"",
      city:""
    }
  }

  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_FILTER:
          let filter = [...state];
          filter.splice(state.findIndex(el => el.name === action.payload.name), 1, {"name":action.payload.name,"value":action.payload.value})
        //return [...filter, {"name":action.payload.name,"value":action.payload.value}];
          return filter;
        case UPDATE_GOD_FILTER:
          //console.log("index in filter by god:", state.gods.findIndex(el => el.name === action.payload.name));
          //console.log("payload in filter by god:", action.payload);
          console.log("gods in filter by god:", state.gods.splice(state.gods.findIndex(el => el.name === action.payload.name), 1, {"name":action.payload.name,"value":action.payload.value}));
          const gods = [...state.gods];
          gods.splice(gods.findIndex(el => el.name === action.payload.name), 1, {"name":action.payload.name,"value":action.payload.value});
          return {...state, gods};
          //return {...state, gods:state.gods}
          //return {...state, gods:state.gods.splice(state.gods.findIndex(el => el.name === action.payload.name), 1, {"name":action.payload.name,"value":action.payload.value})};
        case UPDATE_TYPE_FILTER:
          console.log("types in filter by types:", state.types);
          console.log("index in filter by type:", state.types.findIndex(el => el.name === action.payload.name));
          //return {...state, types:state.types}  
          const types = [...state.types];
          types.splice(state.types.findIndex(el => el.name === action.payload.name), 1, {"name":action.payload.name,"value":action.payload.value});
          return {...state, types};
        case UPDATE_YUGA_FILTER:
          const yugas = [...state.yugas];
          yugas.splice(yugas.findIndex(el => el.name === action.payload.name), 1, {"name":action.payload.name,"value":action.payload.value});
          return {...state, yugas};
        case UPDATE_NAME_FILTER:
          return {...state, bhaktaName: action.payload}
        case UPDATE_CITY_FILTER:
            return {...state, city: action.payload}
        case FETCH_FILTER:
          return {...state};
        case RESET_FILTER:
          return resetFilter(state);
      default:
        return state;
    }
  };
  