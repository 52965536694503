import React from "react";
import { useState, useEffect } from "react";
// This import will give us the params that we passed when calling the route
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {connect} from 'react-redux';
import { Alert, Backdrop, Button, CircularProgress, Grid, Stack } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FlexBox from './flexbox/FlexBox';
import {fetchBhakta } from '../actions';
import Bhakta from './Bhakta';

const BhaktaProfile = ({status, bhakta, fetchBhakta}) => {
  const { name } = useParams();
  const navigate = useNavigate();
  const [bhaktaName, setBhaktaName] = useState(name);
  //fetchBhakta(name);
  useEffect(() => {
    console.log("fetching Bhakta from BhaktaProfile - ", bhaktaName);
    fetchBhakta(bhaktaName);
  }, []);
  //const bhakta = places.find(place => place.name.toLowerCase() == name.toLowerCase());
  const handleUpdate = () => {
         
  };
  return (
    <div>
      {status && status.result === "FETCH_BHAKTA_SUCCESS" && 
      <Grid sx={{marginX:3, marginY: 3}}>
        <Button variant="contained" onClick={()=>navigate(-1)} sx={{marginBottom:2}}><ArrowBackIcon/></Button>
        <Bhakta place={bhakta} source='BhaktaProfile'/>
    </Grid>}
    <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={status && status.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
    {status && status.error && <Alert severity="error">{status.error}</Alert>}
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log("state in Bhaktaprofile:", state);
    return {
        bhakta: state.bhakta,
        status: state.status
      };
}

export default connect(mapStateToProps, {fetchBhakta})(BhaktaProfile);