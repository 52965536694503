import React from 'react';
import {connect, useSelector} from 'react-redux';
import { Field, FormSection, getFormValues, reduxForm } from 'redux-form';
import { FilterList, Star } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormGroup,
  Rating,
  Stack,
  styled,
} from "@mui/material";
import { SwitchUnstyled, switchUnstyledClasses } from "@mui/core";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import { Box } from "@mui/system";
import AppCheckBox from "../input-fields/AppCheckBox";
import AppRadio from "../input-fields/AppRadio";
//import ColorRadio from "components/ColorRadio";
import FlexBox from "../flexbox/FlexBox";
import FlexBetween from "../flexbox/FlexBetween";
import AppTextField from "../input-fields/AppTextField";
import { H3, H5 } from "../Typography";
import Restore from "../../icons/Restore";
import {filterBhaktas, setView, updateFilter, updateGodFilter, updateTypeFilter, updateYugaFilter, resetFilter} from '../../actions';
import {UPDATE_GOD_FILTER, UPDATE_TYPE_FILTER, UPDATE_YUGA_FILTER, UPDATE_NAME_FILTER, UPDATE_CITY_FILTER} from '../../actions/types';
import FilterForm from '../../forms/FilterForm';
import { useState } from "react"; // styled components

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  "& .MuiCheckbox-root": {
    padding: "5px 9px",
  },
  "& .MuiRadio-root": {
    paddingTop: 5,
    paddingBottom: 5,
  },
  "& .MuiTypography-root": {
    fontSize: 12,
    fontWeight: 500,
  },
})); // -------------------------------------------------------

// -------------------------------------------------------

const CustomSwitchStyle = styled("span")(({ theme }) => ({
  width: 52,
  height: 24,
  fontSize: 0,
  cursor: "pointer",
  position: "relative",
  display: "inline-block",
  [`&.${switchUnstyledClasses.disabled}`]: {
    opacity: 0.4,
    cursor: "not-allowed",
  },
  [`& .${switchUnstyledClasses.track}`]: {
    width: "100%",
    height: "100%",
    display: "block",
    borderRadius: 26,
    position: "absolute",
    backgroundColor: theme.palette.grey[600],
  },
  [`& .${switchUnstyledClasses.thumb}`]: {
    top: 3,
    left: 3,
    width: 18,
    height: 18,
    display: "block",
    borderRadius: 16,
    position: "relative",
    backgroundColor: "#fff",
    transition: "all 200ms ease",
  },
  [`&.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb}`]: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    boxShadow: "0 0 1px 8px rgba(0, 0, 0, 0.25)",
  },
  [`&.${switchUnstyledClasses.checked}`]: {
    [`.${switchUnstyledClasses.thumb}`]: {
      top: 3,
      left: 30,
      backgroundColor: "#fff",
    },
    [`.${switchUnstyledClasses.track}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${switchUnstyledClasses.input}`]: {
    top: 0,
    left: 0,
    margin: 0,
    zIndex: 1,
    opacity: 0,
    width: "100%",
    height: "100%",
    cursor: "inherit",
    position: "absolute",
  },
}));

const FilterBar = ({ open, onClose, view, filterBhaktas, setView, filterData, updateFilter, updateGodFilter, updateTypeFilter, updateYugaFilter, resetFilter }) => {
  const [colorSelect, setColorSelect] = useState("red");
  const [rating, setRating] = useState(2);
  const [selectCategory, setSelectCategory] = useState("shoes"); // handle change color function
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [checkBoxFilters, setcheckBoxFilters] = useState([
    {"name":"ganesha", "value":false},{"name":"shiva", "value":false},{"name":"vishnu", "value":false},
    {"name":"skanda", "value":false},{"name":"shakti", "value":false},{"name":"paramatma", "value":false},
    {"name":"bhakta", "value":false},{"name":"alwar", "value":false},{"name":"nayanar", "value":false},
    {"name":"sati", "value":false},{"name":"satya", "value":false},{"name":"treta", "value":false},
    {"name":"dwapara", "value":false},{"name":"kali", "value":false},{"name":"bhaktaName", "value":false},,{"city":"ganesha", "value":false}
  ]);
  const gods = [{"label":"Ganesha", "value":"ganesha"}, {"label":"Shiva", "value":"shiva"}, 
  {"label":"Vishnu", "value":"vishnu"}, {"label":"Skanda", "value":"skanda"}, {"label":"Shakti", "value":"shakti"}];
  const types = [{"label":"Paramatma", "value":"paramatma"}, {"label":"Bhakta", "value":"bhakta"}, 
  {"label":"Alwar", "value":"alwar"}, {"label":"Nayanar", "value":"nayanar"}, {"label":"Sati", "value":"sati"}];
  const yugas = [{"label":"Satya", "value":"satya"}, {"label":"Treta", "value":"treta"}, 
  {"label":"Dwapara", "value":"dwapara"}, {"label":"Kali", "value":"kali"}];

  const handleChangeColor = (event) => {
    setColorSelect(event.target.value);
  }; // handle change category function

  const changeCategory = (event) => {
    setSelectCategory(event.target.value);
  };

  const handleFiltersClose = () => {
    setFilterModalOpen(false);
  };

  const GodFilter = () => {
    return (
      <Box>
        <H5 mb={2}>God</H5>
          <FormGroup>
            {gods.map(god => renderGodCheckbox(god))}
          </FormGroup>
      </Box>
    );
  }

  const TypeFilter = () => {
    return (
      <Box>
        <H5 mt={3} mb={2}>
            Type
          </H5>
            <FormGroup>
              {types.map(type => renderTypeCheckbox(type))}
            </FormGroup>
      </Box>
    );
  }

  const YugaFilter = () => {
    return (
      <Box>
        <H5 mt={3} mb={2}>
            Yuga
          </H5>
          <FormGroup>
            {yugas.map(yuga => renderYugaCheckbox(yuga))}
          </FormGroup>
      </Box>
    );
  }

  const handleViewChange = (event) => {
    setView(event.target.checked? "map":"table");
  };
  
  const handleFilterUpdate = (event) => {
    //const formValues = useSelector(state => getFormValues('filterForm')(state))
    console.log("filter data:", filterData);
    //console.log("VIEW data:", view);
    //console.log("form from props:", form);
    console.log("event from filter:", event);
    updateFilter({name:event.target.name, value:event.target.checked});
  };

  const handleGodFilterUpdate = (event) => {
    //updateGodFilter({name:event.target.name, value:event.target.checked});
    filterBhaktas(UPDATE_GOD_FILTER, {name:event.target.name, value:event.target.checked});
  };

  const handleTypeFilterUpdate = (event) => {
    //updateTypeFilter({name:event.target.name, value:event.target.checked});
    filterBhaktas(UPDATE_TYPE_FILTER, {name:event.target.name, value:event.target.checked});
  };

  const handleYugaFilterUpdate = (event) => {
    //updateYugaFilter({name:event.target.name, value:event.target.checked});
    filterBhaktas(UPDATE_YUGA_FILTER, {name:event.target.name, value:event.target.checked});
  };

  const renderCheckbox = (item) => {
    //console.log("filter for:", item, filterData.find(el => el.name === item.value).value);
    return (<FormControl>
        <StyledFormControlLabel
              name={item.value}
              value={item.value}
              control={<AppCheckBox />}
              //control={<Checkbox />}
              label={item.label}
              onChange={handleFilterUpdate}
              //checked={filterData[filterData.findIndex(el => el.name === item.value)].value}
              checked={filterData.find(el => el.name === item.value).value}
              //checked={checkBoxFilters.find(cbf => cbf.name === item.value).value}
              //checked={checkBoxFilters[0].value}
            />
    </FormControl>);
}

const renderGodCheckbox = (item) => {
  return (
    <FormControl>
        <StyledFormControlLabel
              name={item.value}
              value={item.value}
              control={<AppCheckBox />}
              label={item.label}
              onChange={handleGodFilterUpdate}
              checked={filterData.gods.find(el => el.name === item.value).value}
            />
    </FormControl>
  );
}

const renderTypeCheckbox = (item) => {
  return (
    <FormControl>
        <StyledFormControlLabel
              name={item.value}
              value={item.value}
              control={<AppCheckBox />}
              label={item.label}
              onChange={handleTypeFilterUpdate}
              checked={filterData.types.find(el => el.name === item.value).value}
            />
    </FormControl>
  );
}

const renderYugaCheckbox = (item) => {
  return (
    <FormControl>
        <StyledFormControlLabel
              name={item.value}
              value={item.value}
              control={<AppCheckBox />}
              label={item.label}
              onChange={handleYugaFilterUpdate}
              checked={filterData.yugas.find(el => el.name === item.value).value}
            />
    </FormControl>
  );
}

  return (
    <Box sx={{backgroundColor: '#fff', height:1095}}>
        <form>
      <FlexBetween sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Button
              color="secondary"
              variant="GreyOutlined"
              startIcon={<FilterList />}
              onClick={() => setFilterModalOpen(true)}
              
            >
              Filter
        </Button>
        <FlexBox alignItems="center" gap={2}>
                <span>List</span>
                <SwitchUnstyled component={CustomSwitchStyle} checked={view && view.viewType == "map"} onChange={handleViewChange}/>
                <span>Map</span>
        </FlexBox>
      </FlexBetween>
      <Dialog
                onClose={handleFiltersClose}
                aria-labelledby="customized-dialog-title"
                open={filterModalOpen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Filters
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleFiltersClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{display:'flex'}}>
                  <GodFilter/>
                  <TypeFilter/>
                  <YugaFilter/>
                </DialogContent>
            </Dialog>
      <Box
        sx={{
          maxWidth: 250,
          display: { xs: 'none', md: 'flex' },
          flexDirection:'column'
        }}
      >
        <FlexBetween padding={2}>
          <H3>Filters</H3>
          <IconButton
            sx={{
              padding: 0,
            }}
            onClick={onClose}
          >
          </IconButton>
        </FlexBetween>
        <Divider />
        <Box padding={2} mt={1}>
          <Stack spacing={2}>
            <GodFilter/>
              <TypeFilter/>
              <YugaFilter/>
              <Button
                fullWidth
                variant="GreyOutlined"
                startIcon={<Restore />}
                sx={{
                  mt: 3,
                }}
                onClick={() => resetFilter()}
              >
                Clear all
              </Button>
            </Stack>
            
        </Box>
      </Box>
    </form>
    </Box>
    
  );
};

const mapStateToProps = (state) => {
  console.log("filter in FilterBar", state.filter);
  return {
    view: state.view,
    //filter: getFormValues('filterForm')(state),
    //filterData: state.form.filterForm.values
    filterData:state.filter
  };
};


export default connect(mapStateToProps, {setView, filterBhaktas, updateFilter, updateGodFilter, updateTypeFilter, updateYugaFilter, resetFilter})(FilterBar);