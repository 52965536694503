
import {
    SET_VIEW
  } from '../actions/types';
  
  const INITIAL_STATE = {viewType:"table"};
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_VIEW:
        return {...state, viewType: action.payload};
      default:
        return state;
    }
  };
  