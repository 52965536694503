import React from 'react';
import { Avatar, Box, Button, Grid, ListItem, ListItemIcon, ListItemText, ListItemAvatar } from "@mui/material";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { H1, H6, Small, Span, Tiny } from "./Typography";

const Contact = () => {
    return (
        <React.Fragment>
            <Box pt={6}>

            </Box>
            <Box margin="auto" textAlign="center" sx={{ bgcolor: '#faf8e3', borderRadius:5 }} p={2} maxWidth={900}>
            <List
      sx={{ width: '100%', maxWidth: 800,  }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader" sx={{ bgcolor: '#faf8e3'}}>
          We can be reached in the following ways
        </ListSubheader>
      }
    >
      <ListItemButton>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary={
          <Tiny color="text.secondary" fontSize={14} py={2} fontWeight={400}>
              <Span fontWeight={900} color="#a84232">Registered Address:</Span> 
              <span> 220, Agraharam, Perambur, Needamanglam, TN-614404</span>
          </Tiny>
        
        } sx={{fontSize:8, fontWeight:200}}/>
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary={<Span fontWeight={900} color="#a84232">Email:</Span>}
        secondary={
            <React.Fragment>
              <Link href="mailto:contact@ShriChandrashekharaBodhendraSaraswathiTrust.org">contact@ShriChandrashekharaBodhendraSaraswathiTrust.org</Link>
            </React.Fragment>
          }/>
      </ListItemButton>
      
    </List>
                
            </Box>
        </React.Fragment>
    )
}

export default Contact;