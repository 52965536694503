import React, { Component } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Grid} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import '../App.css';


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {anchorElNav:null};
    //this.state = { term: "" };
    this.navItems = [{"name":'Home', "path":"/"}, {"name":'Objectives', "path": "/objectives"}, 
                    {"name":'Events', "path":"/events"}, {"name":'Chaithanya Kuteera Prakashaha', "path":"/chaithanya-kuteera-prakashaha"},
                    {"name":'Contact', "path":"/contact"}];
  }

  handleOpenNavMenu(event){
    this.setState({anchorElNav:event.currentTarget});
  }

  handleCloseNavMenu(){
    this.setState({anchorElNav:null});
  }

  render() {

    let styles = {
      formStyle: {
        "flexDirection": "row"
      }
    };

    //const navItems = ['Home', 'Trust', 'Activities', 'Chaithanya Kuteera Prakashaha', 'Contact'];

    

    const returnMUIAppBar = () => {
      return (
        <Box sx={{ flexGrow: 1, backgroundColor:'#db622b' }}>
          <AppBar position="static" color="transparent">
          
            <Toolbar sx={{ backgroundColor:'#a84232' }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              //onClick={handleOpenNavMenu}
              onClick={this.handleOpenNavMenu.bind(this)}
              color="default"
            >
              <MenuIcon style={{ color: '#fff' }}/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={this.state.anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(this.state.anchorElNav)}
              //onClose={handleCloseNavMenu}
              onClose={this.handleCloseNavMenu.bind(this)}
              sx={{
                display: { xs: 'block', md: 'none' },
                //backgroundColor:'#006064'
              }}
            >
              
              {this.navItems.map((page) => (
                <MenuItem key={page.name} onClick={this.handleCloseNavMenu.bind(this)}>
                  <Link style={{ color: '#006064' }} to={page.path}>
                    <Typography textAlign="center">{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Grid container>
            <Grid item xs={2}>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <img alt="Google sign-in" src="/maha-periyava.png" width="50" height="50"/>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <img alt="Google sign-in" src="/maha-periyava.png" width="100" height="100"/>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h5" component="div" sx={{ flexGrow: 1, color: 'white', fontWeight:'bold' }}>
                  Shri Chandrashekhara Bodhendra Saraswathi Trust
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <img alt="Google sign-in" src="/bodhendra.png" width="50" height="50"/>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  <img alt="Google sign-in" src="/bodhendra.png" width="100" height="100"/>
                </Box>
            </Grid>
          </Grid>
            
              
              
          
              
            </Toolbar>
            
          </AppBar>
          <React.Fragment x={{ flexGrow: 1, justifyContent:'space-between' }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {this.navItems.map((page) => (
                  <Button sx={{ color: '#fff' }}>
                  <Link style={{ color: '#fff' }} to={page.path}>{page.name}</Link>
                </Button>
                ))}
              

            </Box>
            
          </React.Fragment>
        </Box>
      );
    };
    return returnMUIAppBar();
  }

}



export default Header;