
import {
  FETCH_PLACES,
  UPDATE_PLACES,
  FILTER_PLACES_BY_GOD,
  FILTER_BHAKTAS_BY_NAME,
  FILTER_BHAKTAS,
  RESET_BHAKTAS,
  ADD_TO_FETCHED_PLACES,
  UPDATE_FETCHED_PLACE,
  FETCH_CITIES
} from '../actions/types';
import places from '../assets/mocked-json/places';
import cities from '../assets/mocked-json/cities';

const INITIAL_STATE = {
  activePlaces: [],
  originalPlaces: [],
};

const INITIAL_STATES = [];

const filterPlaces = (formValues) => {
    for(let god in formValues.gods){
      if(formValues.gods[god] === true){
        places = places.map(place => {
          return (place.god.toLowerCase() === god.toLowerCase())?place:{...place, display:true}
        });
      }
      else {
        places = places.map(place => {
          return (place.god.toLowerCase() === god.toLowerCase())?place:{...place, display:false}
        });
      }
    }
    for(let yuga in formValues.yugas){
      if(formValues.yugas[yuga] === true){
        places = places.map(place => {
          return (place.yuga.toLowerCase() === yuga.toLowerCase())?place:{...place, display:true}
        });
      }
      else {
        places = places.map(place => {
          return (place.yuga.toLowerCase() === yuga.toLowerCase())?place:{...place, display:false}
        });
      }
    }
    for(let country in formValues.countries){
      if(formValues.countries[country] === true){
        places = places.map(place => {
          return (place.country.toLowerCase() === country.toLowerCase())?place:{...place, display:true}
        });
      }
      else {
        places = places.map(place => {
          return (place.country.toLowerCase() === country.toLowerCase())?place:{...place, display:false}
        });
      }
    }
    return places;
}

const filterPlacesByParameters = (params) => {
  
  return places;
}

const filterBhaktas = (state, payload) => {
  let activePlaces = state.originalPlaces;
  state.activePlaces = state.originalPlaces;
  if (payload.gods.findIndex(god => god.value) != -1) {
    activePlaces = activePlaces.filter(bhakta => {
      let indicator = payload.gods.find(god =>  god.name.toLowerCase() == bhakta.god.toLowerCase());
      return indicator.value;
    });
  }
  if (payload.types.findIndex(type => type.value) != -1) {
      activePlaces = activePlaces.filter(bhakta => {
      let indicator = payload.types.find(type =>  type.name.toLowerCase() == bhakta.type.toLowerCase());
      return indicator.value;
    });
  }
  if (payload.yugas.findIndex(yuga => yuga.value) != -1) {
    activePlaces = activePlaces.filter(bhakta => {
      let indicator = payload.yugas.find(yuga =>  yuga.name.toLowerCase() == bhakta.yuga.toLowerCase());
      return indicator.value;
    });
  }
  if (payload.bhaktaName) {
    activePlaces = activePlaces.filter(bhakta => bhakta.name.toLowerCase().includes(payload.bhaktaName.toLowerCase()));
  }
  if (payload.city) {
    activePlaces = activePlaces.filter(bhakta => bhakta.city.includes(payload.city));
  }
  return {activePlaces:activePlaces, originalPlaces:state.originalPlaces};
} 

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PLACES:
      console.log("places in placesReducer", action.payload);
      //return [...action.payload];
      return {activePlaces:action.payload, originalPlaces:action.payload}
    case UPDATE_PLACES:
      return filterPlaces(action.payload);
    case ADD_TO_FETCHED_PLACES:
          //return [...state, ...action.payload];
          return {activePlaces:[...state.activePlaces, ...action.payload], originalPlaces:[...state.originalPlaces, ...action.payload]}
    case UPDATE_FETCHED_PLACE:
      //return [...state, ...action.payload];
      return {activePlaces:[...state.activePlaces, ...action.payload], originalPlaces:[...state.originalPlaces, ...action.payload]}
    case RESET_BHAKTAS:
      return {activePlaces:[...state.originalPlaces], originalPlaces:[...state.originalPlaces]}
    case FILTER_BHAKTAS_BY_NAME:
        //return filterPlacesBybhakta(action.payload);
        let activePlaces = state.activePlaces.filter(bhakta => bhakta.name.includes(action.payload));
        return {activePlaces:activePlaces, originalPlaces:state.originalPlaces};
    case FILTER_BHAKTAS:
      return filterBhaktas(state, action.payload);
      default:
      return state;
  }
};
