const cities = [
  {
      "id": 0,
      "name": "Kailasam/Vaikuntam",
      "state": "",
      "country": ""
  },
  {
      "id": 1,
      "name": "Mumbai",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 2,
      "name": "Delhi",
      "state": "Delhi",
      "country": "Bharat"
  },
  {
      "id": 3,
      "name": "Bengaluru",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 4,
      "name": "Ahmedabad",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 5,
      "name": "Hyderabad",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 6,
      "name": "Chennai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 7,
      "name": "Kolkata",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 8,
      "name": "Pune",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 9,
      "name": "Jaipur",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 10,
      "name": "Surat",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 11,
      "name": "Lucknow",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 12,
      "name": "Kanpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 13,
      "name": "Nagpur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 14,
      "name": "Patna",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 15,
      "name": "Indore",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 16,
      "name": "Thane",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 17,
      "name": "Bhopal",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 18,
      "name": "Visakhapatnam",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 19,
      "name": "Vadodara",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 20,
      "name": "Firozabad",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 21,
      "name": "Ludhiana",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 22,
      "name": "Rajkot",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 23,
      "name": "Agra",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 24,
      "name": "Siliguri",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 25,
      "name": "Nashik",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 26,
      "name": "Faridabad",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 27,
      "name": "Patiala",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 28,
      "name": "Meerut",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 29,
      "name": "Kalyan-Dombivali",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 30,
      "name": "Vasai-Virar",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 31,
      "name": "Varanasi",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 32,
      "name": "Srinagar",
      "state": "Jammu and Kashmir",
      "country": "Bharat"
  },
  {
      "id": 33,
      "name": "Dhanbad",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 34,
      "name": "Jodhpur",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 35,
      "name": "Amritsar",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 36,
      "name": "Raipur",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 37,
      "name": "Allahabad",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 38,
      "name": "Coimbatore",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 39,
      "name": "Jabalpur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 40,
      "name": "Gwalior",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 41,
      "name": "Vijayawada",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 42,
      "name": "Madurai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 43,
      "name": "Guwahati",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 44,
      "name": "Chandigarh",
      "state": "Chandigarh",
      "country": "Bharat"
  },
  {
      "id": 45,
      "name": "Hubli-Dharwad",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 46,
      "name": "Amroha",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 47,
      "name": "Moradabad",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 48,
      "name": "Gurgaon",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 49,
      "name": "Aligarh",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 50,
      "name": "Solapur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 51,
      "name": "Ranchi",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 52,
      "name": "Jalandhar",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 53,
      "name": "Tiruchirappalli",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 54,
      "name": "Bhubaneswar",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 55,
      "name": "Salem",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 56,
      "name": "Warangal",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 57,
      "name": "Mira-Bhayandar",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 58,
      "name": "Thiruvananthapuram",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 59,
      "name": "Bhiwandi",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 60,
      "name": "Saharanpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 61,
      "name": "Guntur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 62,
      "name": "Amravati",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 63,
      "name": "Bikaner",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 64,
      "name": "Noida",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 65,
      "name": "Jamshedpur",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 66,
      "name": "Bhilai Nagar",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 67,
      "name": "Cuttack",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 68,
      "name": "Kochi",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 69,
      "name": "Udaipur",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 70,
      "name": "Bhavnagar",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 71,
      "name": "Dehradun",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 72,
      "name": "Asansol",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 73,
      "name": "Nanded-Waghala",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 74,
      "name": "Ajmer",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 75,
      "name": "Jamnagar",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 76,
      "name": "Ujjain",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 77,
      "name": "Sangli",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 78,
      "name": "Loni",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 79,
      "name": "Jhansi",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 80,
      "name": "Pondicherry",
      "state": "Puducherry",
      "country": "Bharat"
  },
  {
      "id": 81,
      "name": "Nellore",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 82,
      "name": "Jammu",
      "state": "Jammu and Kashmir",
      "country": "Bharat"
  },
  {
      "id": 83,
      "name": "Belagavi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 84,
      "name": "Raurkela",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 85,
      "name": "Mangaluru",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 86,
      "name": "Tirunelveli",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 87,
      "name": "Malegaon",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 88,
      "name": "Gaya",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 89,
      "name": "Tiruppur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 90,
      "name": "Davanagere",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 91,
      "name": "Kozhikode",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 92,
      "name": "Akola",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 93,
      "name": "Kurnool",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 94,
      "name": "Bokaro Steel City",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 95,
      "name": "Rajahmundry",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 96,
      "name": "Ballari",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 97,
      "name": "Agartala",
      "state": "Tripura",
      "country": "Bharat"
  },
  {
      "id": 98,
      "name": "Bhagalpur",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 99,
      "name": "Latur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 100,
      "name": "Dhule",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 101,
      "name": "Korba",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 102,
      "name": "Bhilwara",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 103,
      "name": "Brahmapur",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 104,
      "name": "Mysore",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 105,
      "name": "Muzaffarpur",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 106,
      "name": "Ahmednagar",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 107,
      "name": "Kollam",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 108,
      "name": "Raghunathganj",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 109,
      "name": "Bilaspur",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 110,
      "name": "Shahjahanpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 111,
      "name": "Thrissur",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 112,
      "name": "Alwar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 113,
      "name": "Kakinada",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 114,
      "name": "Nizamabad",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 115,
      "name": "Sagar",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 116,
      "name": "Tumkur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 117,
      "name": "Hisar",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 118,
      "name": "Rohtak",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 119,
      "name": "Panipat",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 120,
      "name": "Darbhanga",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 121,
      "name": "Kharagpur",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 122,
      "name": "Aizawl",
      "state": "Mizoram",
      "country": "Bharat"
  },
  {
      "id": 123,
      "name": "Ichalkaranji",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 124,
      "name": "Tirupati",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 125,
      "name": "Karnal",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 126,
      "name": "Bathinda",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 127,
      "name": "Rampur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 128,
      "name": "Shivamogga",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 129,
      "name": "Ratlam",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 130,
      "name": "Modinagar",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 131,
      "name": "Durg",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 132,
      "name": "Shillong",
      "state": "Meghalaya",
      "country": "Bharat"
  },
  {
      "id": 133,
      "name": "Imphal",
      "state": "Manipur",
      "country": "Bharat"
  },
  {
      "id": 134,
      "name": "Hapur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 135,
      "name": "Ranipet",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 136,
      "name": "Anantapur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 137,
      "name": "Arrah",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 138,
      "name": "Karimnagar",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 139,
      "name": "Parbhani",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 140,
      "name": "Etawah",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 141,
      "name": "Bharatpur",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 142,
      "name": "Begusarai",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 143,
      "name": "New Delhi",
      "state": "Delhi",
      "country": "Bharat"
  },
  {
      "id": 144,
      "name": "Chhapra",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 145,
      "name": "Kadapa",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 146,
      "name": "Ramagundam",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 147,
      "name": "Pali",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 148,
      "name": "Satna",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 149,
      "name": "Vizianagaram",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 150,
      "name": "Katihar",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 151,
      "name": "Hardwar",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 152,
      "name": "Sonipat",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 153,
      "name": "Nagercoil",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 154,
      "name": "Thanjavur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 155,
      "name": "Murwara (Katni)",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 156,
      "name": "Naihati",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 157,
      "name": "Sambhal",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 158,
      "name": "Nadiad",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 159,
      "name": "Yamunanagar",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 160,
      "name": "English Bazar",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 161,
      "name": "Eluru",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 162,
      "name": "Munger",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 163,
      "name": "Panchkula",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 164,
      "name": "Raayachuru",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 165,
      "name": "Panvel",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 166,
      "name": "Deoghar",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 167,
      "name": "Ongole",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 168,
      "name": "Nandyal",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 169,
      "name": "Morena",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 170,
      "name": "Bhiwani",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 171,
      "name": "Porbandar",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 172,
      "name": "Palakkad",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 173,
      "name": "Anand",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 174,
      "name": "Purnia",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 175,
      "name": "Baharampur",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 176,
      "name": "Barmer",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 177,
      "name": "Morvi",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 178,
      "name": "Orai",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 179,
      "name": "Bahraich",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 180,
      "name": "Sikar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 181,
      "name": "Vellore",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 182,
      "name": "Singrauli",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 183,
      "name": "Khammam",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 184,
      "name": "Mahesana",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 185,
      "name": "Silchar",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 186,
      "name": "Sambalpur",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 187,
      "name": "Rewa",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 188,
      "name": "Unnao",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 189,
      "name": "Hugli-Chinsurah",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 190,
      "name": "Raiganj",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 191,
      "name": "Phusro",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 192,
      "name": "Adityapur",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 193,
      "name": "Alappuzha",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 194,
      "name": "Bahadurgarh",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 195,
      "name": "Machilipatnam",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 196,
      "name": "Rae Bareli",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 197,
      "name": "Jalpaiguri",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 198,
      "name": "Bharuch",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 199,
      "name": "Pathankot",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 200,
      "name": "Hoshiarpur",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 201,
      "name": "Baramula",
      "state": "Jammu and Kashmir",
      "country": "Bharat"
  },
  {
      "id": 202,
      "name": "Adoni",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 203,
      "name": "Jind",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 204,
      "name": "Tonk",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 205,
      "name": "Tenali",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 206,
      "name": "Kancheepuram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 207,
      "name": "Vapi",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 208,
      "name": "Sirsa",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 209,
      "name": "Navsari",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 210,
      "name": "Mahbubnagar",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 211,
      "name": "Puri",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 212,
      "name": "Robertson Pet",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 213,
      "name": "Erode",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 214,
      "name": "Batala",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 215,
      "name": "Haldwani-cum-Kathgodam",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 216,
      "name": "Vidisha",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 217,
      "name": "Saharsa",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 218,
      "name": "Thanesar",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 219,
      "name": "Chittoor",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 220,
      "name": "Veraval",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 221,
      "name": "Lakhimpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 222,
      "name": "Sitapur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 223,
      "name": "Hindupur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 224,
      "name": "Santipur",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 225,
      "name": "Balurghat",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 226,
      "name": "Ganjbasoda",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 227,
      "name": "Moga",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 228,
      "name": "Proddatur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 229,
      "name": "Srinagar",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 230,
      "name": "Medinipur",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 231,
      "name": "Habra",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 232,
      "name": "Sasaram",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 233,
      "name": "Hajipur",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 234,
      "name": "Bhuj",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 235,
      "name": "Shivpuri",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 236,
      "name": "Ranaghat",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 237,
      "name": "Shimla",
      "state": "Himachal Pradesh",
      "country": "Bharat"
  },
  {
      "id": 238,
      "name": "Tiruvannamalai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 239,
      "name": "Kaithal",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 240,
      "name": "Rajnandgaon",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 241,
      "name": "Godhra",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 242,
      "name": "Hazaribag",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 243,
      "name": "Bhimavaram",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 244,
      "name": "Mandsaur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 245,
      "name": "Dibrugarh",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 246,
      "name": "Kolar",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 247,
      "name": "Bankura",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 248,
      "name": "Mandya",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 249,
      "name": "Dehri-on-Sone",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 250,
      "name": "Madanapalle",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 251,
      "name": "Malerkotla",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 252,
      "name": "Lalitpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 253,
      "name": "Bettiah",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 254,
      "name": "Pollachi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 255,
      "name": "Khanna",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 256,
      "name": "Neemuch",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 257,
      "name": "Palwal",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 258,
      "name": "Palanpur",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 259,
      "name": "Guntakal",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 260,
      "name": "Nabadwip",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 261,
      "name": "Udupi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 262,
      "name": "Jagdalpur",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 263,
      "name": "Motihari",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 264,
      "name": "Pilibhit",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 265,
      "name": "Dimapur",
      "state": "Nagaland",
      "country": "Bharat"
  },
  {
      "id": 266,
      "name": "Mohali",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 267,
      "name": "Sadulpur",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 268,
      "name": "Rajapalayam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 269,
      "name": "Dharmavaram",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 270,
      "name": "Kashipur",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 271,
      "name": "Sivakasi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 272,
      "name": "Darjiling",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 273,
      "name": "Chikkamagaluru",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 274,
      "name": "Gudivada",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 275,
      "name": "Baleshwar Town",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 276,
      "name": "Mancherial",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 277,
      "name": "Srikakulam",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 278,
      "name": "Adilabad",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 279,
      "name": "Yavatmal",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 280,
      "name": "Barnala",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 281,
      "name": "Nagaon",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 282,
      "name": "Narasaraopet",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 283,
      "name": "Raigarh",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 284,
      "name": "Roorkee",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 285,
      "name": "Valsad",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 286,
      "name": "Ambikapur",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 287,
      "name": "Giridih",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 288,
      "name": "Chandausi",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 289,
      "name": "Purulia",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 290,
      "name": "Patan",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 291,
      "name": "Bagaha",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 292,
      "name": "Hardoi",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 293,
      "name": "Achalpur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 294,
      "name": "Osmanabad",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 295,
      "name": "Deesa",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 296,
      "name": "Nandurbar",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 297,
      "name": "Azamgarh",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 298,
      "name": "Ramgarh",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 299,
      "name": "Firozpur",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 300,
      "name": "Baripada Town",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 301,
      "name": "Karwar",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 302,
      "name": "Siwan",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 303,
      "name": "Rajampet",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 304,
      "name": "Pudukkottai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 305,
      "name": "Anantnag",
      "state": "Jammu and Kashmir",
      "country": "Bharat"
  },
  {
      "id": 306,
      "name": "Tadpatri",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 307,
      "name": "Satara",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 308,
      "name": "Bhadrak",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 309,
      "name": "Kishanganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 310,
      "name": "Suryapet",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 311,
      "name": "Wardha",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 312,
      "name": "Ranebennuru",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 313,
      "name": "Amreli",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 314,
      "name": "Neyveli (TS)",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 315,
      "name": "Jamalpur",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 316,
      "name": "Marmagao",
      "state": "Goa",
      "country": "Bharat"
  },
  {
      "id": 317,
      "name": "Udgir",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 318,
      "name": "Tadepalligudem",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 319,
      "name": "Nagapattinam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 320,
      "name": "Buxar",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 321,
      "name": "Aurangabad",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 322,
      "name": "Jehanabad",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 323,
      "name": "Phagwara",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 324,
      "name": "Khair",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 325,
      "name": "Sawai Madhopur",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 326,
      "name": "Kapurthala",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 327,
      "name": "Chilakaluripet",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 328,
      "name": "Aurangabad",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 329,
      "name": "Malappuram",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 330,
      "name": "Rewari",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 331,
      "name": "Nagaur",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 332,
      "name": "Sultanpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 333,
      "name": "Nagda",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 334,
      "name": "Port Blair",
      "state": "Andaman and Nicobar Islands",
      "country": "Bharat"
  },
  {
      "id": 335,
      "name": "Lakhisarai",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 336,
      "name": "Panaji",
      "state": "Goa",
      "country": "Bharat"
  },
  {
      "id": 337,
      "name": "Tinsukia",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 338,
      "name": "Itarsi",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 339,
      "name": "Kohima",
      "state": "Nagaland",
      "country": "Bharat"
  },
  {
      "id": 340,
      "name": "Balangir",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 341,
      "name": "Nawada",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 342,
      "name": "Jharsuguda",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 343,
      "name": "Jagtial",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 344,
      "name": "Viluppuram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 345,
      "name": "Amalner",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 346,
      "name": "Zirakpur",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 347,
      "name": "Tanda",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 348,
      "name": "Tiruchengode",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 349,
      "name": "Nagina",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 350,
      "name": "Yemmiganur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 351,
      "name": "Vaniyambadi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 352,
      "name": "Sarni",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 353,
      "name": "Theni Allinagaram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 354,
      "name": "Margao",
      "state": "Goa",
      "country": "Bharat"
  },
  {
      "id": 355,
      "name": "Akot",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 356,
      "name": "Sehore",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 357,
      "name": "Mhow Cantonment",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 358,
      "name": "Kot Kapura",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 359,
      "name": "Makrana",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 360,
      "name": "Pandharpur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 361,
      "name": "Miryalaguda",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 362,
      "name": "Shamli",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 363,
      "name": "Seoni",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 364,
      "name": "Ranibennur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 365,
      "name": "Kadiri",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 366,
      "name": "Shrirampur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 367,
      "name": "Rudrapur",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 368,
      "name": "Parli",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 369,
      "name": "Najibabad",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 370,
      "name": "Nirmal",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 371,
      "name": "Udhagamandalam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 372,
      "name": "Shikohabad",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 373,
      "name": "Jhumri Tilaiya",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 374,
      "name": "Aruppukkottai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 375,
      "name": "Ponnani",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 376,
      "name": "Jamui",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 377,
      "name": "Sitamarhi",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 378,
      "name": "Chirala",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 379,
      "name": "Anjar",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 380,
      "name": "Karaikal",
      "state": "Puducherry",
      "country": "Bharat"
  },
  {
      "id": 381,
      "name": "Hansi",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 382,
      "name": "Anakapalle",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 383,
      "name": "Mahasamund",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 384,
      "name": "Faridkot",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 385,
      "name": "Saunda",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 386,
      "name": "Dhoraji",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 387,
      "name": "Paramakudi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 388,
      "name": "Balaghat",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 389,
      "name": "Sujangarh",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 390,
      "name": "Khambhat",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 391,
      "name": "Muktsar",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 392,
      "name": "Rajpura",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 393,
      "name": "Kavali",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 394,
      "name": "Dhamtari",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 395,
      "name": "Ashok Nagar",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 396,
      "name": "Sardarshahar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 397,
      "name": "Mahuva",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 398,
      "name": "Bargarh",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 399,
      "name": "Kamareddy",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 400,
      "name": "Sahibganj",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 401,
      "name": "Kothagudem",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 402,
      "name": "Ramanagaram",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 403,
      "name": "Gokak",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 404,
      "name": "Tikamgarh",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 405,
      "name": "Araria",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 406,
      "name": "Rishikesh",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 407,
      "name": "Shahdol",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 408,
      "name": "Medininagar (Daltonganj)",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 409,
      "name": "Arakkonam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 410,
      "name": "Washim",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 411,
      "name": "Sangrur",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 412,
      "name": "Bodhan",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 413,
      "name": "Fazilka",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 414,
      "name": "Palacole",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 415,
      "name": "Keshod",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 416,
      "name": "Sullurpeta",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 417,
      "name": "Wadhwan",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 418,
      "name": "Gurdaspur",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 419,
      "name": "Vatakara",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 420,
      "name": "Tura",
      "state": "Meghalaya",
      "country": "Bharat"
  },
  {
      "id": 421,
      "name": "Narnaul",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 422,
      "name": "Kharar",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 423,
      "name": "Yadgir",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 424,
      "name": "Ambejogai",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 425,
      "name": "Ankleshwar",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 426,
      "name": "Savarkundla",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 427,
      "name": "Paradip",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 428,
      "name": "Virudhachalam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 429,
      "name": "Kanhangad",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 430,
      "name": "Kadi",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 431,
      "name": "Srivilliputhur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 432,
      "name": "Gobindgarh",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 433,
      "name": "Tindivanam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 434,
      "name": "Mansa",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 435,
      "name": "Taliparamba",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 436,
      "name": "Manmad",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 437,
      "name": "Tanuku",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 438,
      "name": "Rayachoti",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 439,
      "name": "Virudhunagar",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 440,
      "name": "Koyilandy",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 441,
      "name": "Jorhat",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 442,
      "name": "Karur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 443,
      "name": "Valparai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 444,
      "name": "Srikalahasti",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 445,
      "name": "Neyyattinkara",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 446,
      "name": "Bapatla",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 447,
      "name": "Fatehabad",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 448,
      "name": "Malout",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 449,
      "name": "Sankarankovil",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 450,
      "name": "Tenkasi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 451,
      "name": "Ratnagiri",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 452,
      "name": "Rabkavi Banhatti",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 453,
      "name": "Sikandrabad",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 454,
      "name": "Chaibasa",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 455,
      "name": "Chirmiri",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 456,
      "name": "Palwancha",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 457,
      "name": "Bhawanipatna",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 458,
      "name": "Kayamkulam",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 459,
      "name": "Pithampur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 460,
      "name": "Nabha",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 461,
      "name": "Shahabad, Hardoi",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 462,
      "name": "Dhenkanal",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 463,
      "name": "Uran Islampur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 464,
      "name": "Gopalganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 465,
      "name": "Bongaigaon City",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 466,
      "name": "Palani",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 467,
      "name": "Pusad",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 468,
      "name": "Sopore",
      "state": "Jammu and Kashmir",
      "country": "Bharat"
  },
  {
      "id": 469,
      "name": "Pilkhuwa",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 470,
      "name": "Tarn Taran",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 471,
      "name": "Renukoot",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 472,
      "name": "Mandamarri",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 473,
      "name": "Shahabad",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 474,
      "name": "Barbil",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 475,
      "name": "Koratla",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 476,
      "name": "Madhubani",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 477,
      "name": "Arambagh",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 478,
      "name": "Gohana",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 479,
      "name": "Ladnu",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 480,
      "name": "Pattukkottai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 481,
      "name": "Sirsi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 482,
      "name": "Sircilla",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 483,
      "name": "Tamluk",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 484,
      "name": "Jagraon",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 485,
      "name": "AlipurdUrban Agglomerationr",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 486,
      "name": "Alirajpur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 487,
      "name": "Tandur",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 488,
      "name": "Naidupet",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 489,
      "name": "Tirupathur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 490,
      "name": "Tohana",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 491,
      "name": "Ratangarh",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 492,
      "name": "Dhubri",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 493,
      "name": "Masaurhi",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 494,
      "name": "Visnagar",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 495,
      "name": "Vrindavan",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 496,
      "name": "Nokha",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 497,
      "name": "Nagari",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 498,
      "name": "Narwana",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 499,
      "name": "Ramanathapuram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 500,
      "name": "Ujhani",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 501,
      "name": "Samastipur",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 502,
      "name": "Laharpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 503,
      "name": "Sangamner",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 504,
      "name": "Nimbahera",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 505,
      "name": "Siddipet",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 506,
      "name": "Suri",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 507,
      "name": "Diphu",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 508,
      "name": "Jhargram",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 509,
      "name": "Shirpur-Warwade",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 510,
      "name": "Tilhar",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 511,
      "name": "Sindhnur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 512,
      "name": "Udumalaipettai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 513,
      "name": "Malkapur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 514,
      "name": "Wanaparthy",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 515,
      "name": "Gudur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 516,
      "name": "Kendujhar",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 517,
      "name": "Mandla",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 518,
      "name": "Mandi",
      "state": "Himachal Pradesh",
      "country": "Bharat"
  },
  {
      "id": 519,
      "name": "Nedumangad",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 520,
      "name": "North Lakhimpur",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 521,
      "name": "Vinukonda",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 522,
      "name": "Tiptur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 523,
      "name": "Gobichettipalayam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 524,
      "name": "Sunabeda",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 525,
      "name": "Wani",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 526,
      "name": "Upleta",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 527,
      "name": "Narasapuram",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 528,
      "name": "Nuzvid",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 529,
      "name": "Tezpur",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 530,
      "name": "Una",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 531,
      "name": "Markapur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 532,
      "name": "Sheopur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 533,
      "name": "Thiruvarur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 534,
      "name": "Sidhpur",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 535,
      "name": "Sahaswan",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 536,
      "name": "Suratgarh",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 537,
      "name": "Shajapur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 538,
      "name": "Rayagada",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 539,
      "name": "Lonavla",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 540,
      "name": "Ponnur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 541,
      "name": "Kagaznagar",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 542,
      "name": "Gadwal",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 543,
      "name": "Bhatapara",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 544,
      "name": "Kandukur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 545,
      "name": "Sangareddy",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 546,
      "name": "Unjha",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 547,
      "name": "Lunglei",
      "state": "Mizoram",
      "country": "Bharat"
  },
  {
      "id": 548,
      "name": "Karimganj",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 549,
      "name": "Kannur",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 550,
      "name": "Bobbili",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 551,
      "name": "Mokameh",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 552,
      "name": "Talegaon Dabhade",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 553,
      "name": "Anjangaon",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 554,
      "name": "Mangrol",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 555,
      "name": "Sunam",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 556,
      "name": "Gangarampur",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 557,
      "name": "Thiruvallur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 558,
      "name": "Tirur",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 559,
      "name": "Rath",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 560,
      "name": "Jatani",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 561,
      "name": "Viramgam",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 562,
      "name": "Rajsamand",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 563,
      "name": "Yanam",
      "state": "Puducherry",
      "country": "Bharat"
  },
  {
      "id": 564,
      "name": "Kottayam",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 565,
      "name": "Panruti",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 566,
      "name": "Dhuri",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 567,
      "name": "Namakkal",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 568,
      "name": "Kasaragod",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 569,
      "name": "Modasa",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 570,
      "name": "Rayadurg",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 571,
      "name": "Supaul",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 572,
      "name": "Kunnamkulam",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 573,
      "name": "Umred",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 574,
      "name": "Bellampalle",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 575,
      "name": "Sibsagar",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 576,
      "name": "Mandi Dabwali",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 577,
      "name": "Ottappalam",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 578,
      "name": "Dumraon",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 579,
      "name": "Samalkot",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 580,
      "name": "Jaggaiahpet",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 581,
      "name": "Goalpara",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 582,
      "name": "Tuni",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 583,
      "name": "Lachhmangarh",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 584,
      "name": "Bhongir",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 585,
      "name": "Amalapuram",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 586,
      "name": "Firozpur Cantt.",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 587,
      "name": "Vikarabad",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 588,
      "name": "Thiruvalla",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 589,
      "name": "Sherkot",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 590,
      "name": "Palghar",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 591,
      "name": "Shegaon",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 592,
      "name": "Jangaon",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 593,
      "name": "Bheemunipatnam",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 594,
      "name": "Panna",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 595,
      "name": "Thodupuzha",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 596,
      "name": "KathUrban Agglomeration",
      "state": "Jammu and Kashmir",
      "country": "Bharat"
  },
  {
      "id": 597,
      "name": "Palitana",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 598,
      "name": "Arwal",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 599,
      "name": "Venkatagiri",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 600,
      "name": "Kalpi",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 601,
      "name": "Rajgarh (Churu)",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 602,
      "name": "Sattenapalle",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 603,
      "name": "Arsikere",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 604,
      "name": "Ozar",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 605,
      "name": "Thirumangalam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 606,
      "name": "Petlad",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 607,
      "name": "Nasirabad",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 608,
      "name": "Phaltan",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 609,
      "name": "Rampurhat",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 610,
      "name": "Nanjangud",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 611,
      "name": "Forbesganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 612,
      "name": "Tundla",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 613,
      "name": "BhabUrban Agglomeration",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 614,
      "name": "Sagara",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 615,
      "name": "Pithapuram",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 616,
      "name": "Sira",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 617,
      "name": "Bhadrachalam",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 618,
      "name": "Charkhi Dadri",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 619,
      "name": "Chatra",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 620,
      "name": "Palasa Kasibugga",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 621,
      "name": "Nohar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 622,
      "name": "Yevla",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 623,
      "name": "Sirhind Fatehgarh Sahib",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 624,
      "name": "Bhainsa",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 625,
      "name": "Parvathipuram",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 626,
      "name": "Shahade",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 627,
      "name": "Chalakudy",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 628,
      "name": "Narkatiaganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 629,
      "name": "Kapadvanj",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 630,
      "name": "Macherla",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 631,
      "name": "Raghogarh-Vijaypur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 632,
      "name": "Rupnagar",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 633,
      "name": "Naugachhia",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 634,
      "name": "Sendhwa",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 635,
      "name": "Byasanagar",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 636,
      "name": "Sandila",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 637,
      "name": "Gooty",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 638,
      "name": "Salur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 639,
      "name": "Nanpara",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 640,
      "name": "Sardhana",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 641,
      "name": "Vita",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 642,
      "name": "Gumia",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 643,
      "name": "Puttur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 644,
      "name": "Jalandhar Cantt.",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 645,
      "name": "Nehtaur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 646,
      "name": "Changanassery",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 647,
      "name": "Mandapeta",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 648,
      "name": "Dumka",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 649,
      "name": "Seohara",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 650,
      "name": "Umarkhed",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 651,
      "name": "Madhupur",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 652,
      "name": "Vikramasingapuram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 653,
      "name": "Punalur",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 654,
      "name": "Kendrapara",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 655,
      "name": "Sihor",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 656,
      "name": "Nellikuppam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 657,
      "name": "Samana",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 658,
      "name": "Warora",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 659,
      "name": "Nilambur",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 660,
      "name": "Rasipuram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 661,
      "name": "Ramnagar",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 662,
      "name": "Jammalamadugu",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 663,
      "name": "Nawanshahr",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 664,
      "name": "Thoubal",
      "state": "Manipur",
      "country": "Bharat"
  },
  {
      "id": 665,
      "name": "Athni",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 666,
      "name": "Cherthala",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 667,
      "name": "Sidhi",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 668,
      "name": "Farooqnagar",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 669,
      "name": "Peddapuram",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 670,
      "name": "Chirkunda",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 671,
      "name": "Pachora",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 672,
      "name": "Madhepura",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 673,
      "name": "Pithoragarh",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 674,
      "name": "Tumsar",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 675,
      "name": "Phalodi",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 676,
      "name": "Tiruttani",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 677,
      "name": "Rampura Phul",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 678,
      "name": "Perinthalmanna",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 679,
      "name": "Padrauna",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 680,
      "name": "Pipariya",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 681,
      "name": "Dalli-Rajhara",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 682,
      "name": "Punganur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 683,
      "name": "Mattannur",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 684,
      "name": "Mathura",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 685,
      "name": "Thakurdwara",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 686,
      "name": "Nandivaram-Guduvancheri",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 687,
      "name": "Mulbagal",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 688,
      "name": "Manjlegaon",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 689,
      "name": "Wankaner",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 690,
      "name": "Sillod",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 691,
      "name": "Nidadavole",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 692,
      "name": "Surapura",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 693,
      "name": "Rajagangapur",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 694,
      "name": "Sheikhpura",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 695,
      "name": "Parlakhemundi",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 696,
      "name": "Kalimpong",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 697,
      "name": "Siruguppa",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 698,
      "name": "Arvi",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 699,
      "name": "Limbdi",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 700,
      "name": "Barpeta",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 701,
      "name": "Manglaur",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 702,
      "name": "Repalle",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 703,
      "name": "Mudhol",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 704,
      "name": "Shujalpur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 705,
      "name": "Mandvi",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 706,
      "name": "Thangadh",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 707,
      "name": "Sironj",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 708,
      "name": "Nandura",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 709,
      "name": "Shoranur",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 710,
      "name": "Nathdwara",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 711,
      "name": "Periyakulam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 712,
      "name": "Sultanganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 713,
      "name": "Medak",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 714,
      "name": "Narayanpet",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 715,
      "name": "Raxaul Bazar",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 716,
      "name": "Rajauri",
      "state": "Jammu and Kashmir",
      "country": "Bharat"
  },
  {
      "id": 717,
      "name": "Pernampattu",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 718,
      "name": "Nainital",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 719,
      "name": "Ramachandrapuram",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 720,
      "name": "Vaijapur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 721,
      "name": "Nangal",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 722,
      "name": "Sidlaghatta",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 723,
      "name": "Punch",
      "state": "Jammu and Kashmir",
      "country": "Bharat"
  },
  {
      "id": 724,
      "name": "Pandhurna",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 725,
      "name": "Wadgaon Road",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 726,
      "name": "Talcher",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 727,
      "name": "Varkala",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 728,
      "name": "Pilani",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 729,
      "name": "Nowgong",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 730,
      "name": "Naila Janjgir",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 731,
      "name": "Mapusa",
      "state": "Goa",
      "country": "Bharat"
  },
  {
      "id": 732,
      "name": "Vellakoil",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 733,
      "name": "Merta City",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 734,
      "name": "Sivaganga",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 735,
      "name": "Mandideep",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 736,
      "name": "Sailu",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 737,
      "name": "Vyara",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 738,
      "name": "Kovvur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 739,
      "name": "Vadalur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 740,
      "name": "Nawabganj",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 741,
      "name": "Padra",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 742,
      "name": "Sainthia",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 743,
      "name": "Siana",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 744,
      "name": "Shahpur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 745,
      "name": "Sojat",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 746,
      "name": "Noorpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 747,
      "name": "Paravoor",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 748,
      "name": "Murtijapur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 749,
      "name": "Ramnagar",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 750,
      "name": "Sundargarh",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 751,
      "name": "Taki",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 752,
      "name": "Saundatti-Yellamma",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 753,
      "name": "Pathanamthitta",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 754,
      "name": "Wadi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 755,
      "name": "Rameshwaram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 756,
      "name": "Tasgaon",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 757,
      "name": "Sikandra Rao",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 758,
      "name": "Sihora",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 759,
      "name": "Tiruvethipuram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 760,
      "name": "Tiruvuru",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 761,
      "name": "Mehkar",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 762,
      "name": "Peringathur",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 763,
      "name": "Perambalur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 764,
      "name": "Manvi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 765,
      "name": "Zunheboto",
      "state": "Nagaland",
      "country": "Bharat"
  },
  {
      "id": 766,
      "name": "Mahnar Bazar",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 767,
      "name": "Attingal",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 768,
      "name": "Shahbad",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 769,
      "name": "Puranpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 770,
      "name": "Nelamangala",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 771,
      "name": "Nakodar",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 772,
      "name": "Lunawada",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 773,
      "name": "Murshidabad",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 774,
      "name": "Mahe",
      "state": "Puducherry",
      "country": "Bharat"
  },
  {
      "id": 775,
      "name": "Lanka",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 776,
      "name": "Rudauli",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 777,
      "name": "Tuensang",
      "state": "Nagaland",
      "country": "Bharat"
  },
  {
      "id": 778,
      "name": "Lakshmeshwar",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 779,
      "name": "Zira",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 780,
      "name": "Yawal",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 781,
      "name": "Thana Bhawan",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 782,
      "name": "Ramdurg",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 783,
      "name": "Pulgaon",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 784,
      "name": "Sadasivpet",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 785,
      "name": "Nargund",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 786,
      "name": "Neem-Ka-Thana",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 787,
      "name": "Memari",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 788,
      "name": "Nilanga",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 789,
      "name": "Naharlagun",
      "state": "Arunachal Pradesh",
      "country": "Bharat"
  },
  {
      "id": 790,
      "name": "Pakaur",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 791,
      "name": "Wai",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 792,
      "name": "Tarikere",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 793,
      "name": "Malavalli",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 794,
      "name": "Raisen",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 795,
      "name": "Lahar",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 796,
      "name": "Uravakonda",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 797,
      "name": "Savanur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 798,
      "name": "Sirohi",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 799,
      "name": "Udhampur",
      "state": "Jammu and Kashmir",
      "country": "Bharat"
  },
  {
      "id": 800,
      "name": "Umarga",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 801,
      "name": "Pratapgarh",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 802,
      "name": "Lingsugur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 803,
      "name": "Usilampatti",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 804,
      "name": "Palia Kalan",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 805,
      "name": "Wokha",
      "state": "Nagaland",
      "country": "Bharat"
  },
  {
      "id": 806,
      "name": "Rajpipla",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 807,
      "name": "Vijayapura",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 808,
      "name": "Rawatbhata",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 809,
      "name": "Sangaria",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 810,
      "name": "Paithan",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 811,
      "name": "Rahuri",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 812,
      "name": "Patti",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 813,
      "name": "Zaidpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 814,
      "name": "Lalsot",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 815,
      "name": "Maihar",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 816,
      "name": "Vedaranyam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 817,
      "name": "Nawapur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 818,
      "name": "Solan",
      "state": "Himachal Pradesh",
      "country": "Bharat"
  },
  {
      "id": 819,
      "name": "Vapi",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 820,
      "name": "Sanawad",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 821,
      "name": "Warisaliganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 822,
      "name": "Revelganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 823,
      "name": "Sabalgarh",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 824,
      "name": "Tuljapur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 825,
      "name": "Simdega",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 826,
      "name": "Musabani",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 827,
      "name": "Kodungallur",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 828,
      "name": "Phulabani",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 829,
      "name": "Umreth",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 830,
      "name": "Narsipatnam",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 831,
      "name": "Nautanwa",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 832,
      "name": "Rajgir",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 833,
      "name": "Yellandu",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 834,
      "name": "Sathyamangalam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 835,
      "name": "Pilibanga",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 836,
      "name": "Morshi",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 837,
      "name": "Pehowa",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 838,
      "name": "Sonepur",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 839,
      "name": "Pappinisseri",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 840,
      "name": "Zamania",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 841,
      "name": "Mihijam",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 842,
      "name": "Purna",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 843,
      "name": "Puliyankudi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 844,
      "name": "Shikarpur, Bulandshahr",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 845,
      "name": "Umaria",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 846,
      "name": "Porsa",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 847,
      "name": "Naugawan Sadat",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 848,
      "name": "Fatehpur Sikri",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 849,
      "name": "Manuguru",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 850,
      "name": "Udaipur",
      "state": "Tripura",
      "country": "Bharat"
  },
  {
      "id": 851,
      "name": "Pipar City",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 852,
      "name": "Pattamundai",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 853,
      "name": "Nanjikottai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 854,
      "name": "Taranagar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 855,
      "name": "Yerraguntla",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 856,
      "name": "Satana",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 857,
      "name": "Sherghati",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 858,
      "name": "Sankeshwara",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 859,
      "name": "Madikeri",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 860,
      "name": "Thuraiyur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 861,
      "name": "Sanand",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 862,
      "name": "Rajula",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 863,
      "name": "Kyathampalle",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 864,
      "name": "Shahabad, Rampur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 865,
      "name": "Tilda Newra",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 866,
      "name": "Narsinghgarh",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 867,
      "name": "Chittur-Thathamangalam",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 868,
      "name": "Malaj Khand",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 869,
      "name": "Sarangpur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 870,
      "name": "Robertsganj",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 871,
      "name": "Sirkali",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 872,
      "name": "Radhanpur",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 873,
      "name": "Tiruchendur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 874,
      "name": "Utraula",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 875,
      "name": "Patratu",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 876,
      "name": "Vijainagar, Ajmer",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 877,
      "name": "Periyasemur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 878,
      "name": "Pathri",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 879,
      "name": "Sadabad",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 880,
      "name": "Talikota",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 881,
      "name": "Sinnar",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 882,
      "name": "Mungeli",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 883,
      "name": "Sedam",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 884,
      "name": "Shikaripur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 885,
      "name": "Sumerpur",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 886,
      "name": "Sattur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 887,
      "name": "Sugauli",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 888,
      "name": "Lumding",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 889,
      "name": "Vandavasi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 890,
      "name": "Titlagarh",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 891,
      "name": "Uchgaon",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 892,
      "name": "Mokokchung",
      "state": "Nagaland",
      "country": "Bharat"
  },
  {
      "id": 893,
      "name": "Paschim Punropara",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 894,
      "name": "Sagwara",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 895,
      "name": "Ramganj Mandi",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 896,
      "name": "Tarakeswar",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 897,
      "name": "Mahalingapura",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 898,
      "name": "Dharmanagar",
      "state": "Tripura",
      "country": "Bharat"
  },
  {
      "id": 899,
      "name": "Mahemdabad",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 900,
      "name": "Manendragarh",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 901,
      "name": "Uran",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 902,
      "name": "Tharamangalam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 903,
      "name": "Tirukkoyilur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 904,
      "name": "Pen",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 905,
      "name": "Makhdumpur",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 906,
      "name": "Maner",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 907,
      "name": "Oddanchatram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 908,
      "name": "Palladam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 909,
      "name": "Mundi",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 910,
      "name": "Nabarangapur",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 911,
      "name": "Mudalagi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 912,
      "name": "Samalkha",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 913,
      "name": "Nepanagar",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 914,
      "name": "Karjat",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 915,
      "name": "Ranavav",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 916,
      "name": "Pedana",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 917,
      "name": "Pinjore",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 918,
      "name": "Lakheri",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 919,
      "name": "Pasan",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 920,
      "name": "Puttur",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 921,
      "name": "Vadakkuvalliyur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 922,
      "name": "Tirukalukundram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 923,
      "name": "Mahidpur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 924,
      "name": "Mussoorie",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 925,
      "name": "Muvattupuzha",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 926,
      "name": "Rasra",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 927,
      "name": "Udaipurwati",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 928,
      "name": "Manwath",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 929,
      "name": "Adoor",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 930,
      "name": "Uthamapalayam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 931,
      "name": "Partur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 932,
      "name": "Nahan",
      "state": "Himachal Pradesh",
      "country": "Bharat"
  },
  {
      "id": 933,
      "name": "Ladwa",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 934,
      "name": "Mankachar",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 935,
      "name": "Nongstoin",
      "state": "Meghalaya",
      "country": "Bharat"
  },
  {
      "id": 936,
      "name": "Losal",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 937,
      "name": "Sri Madhopur",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 938,
      "name": "Ramngarh",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 939,
      "name": "Mavelikkara",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 940,
      "name": "Rawatsar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 941,
      "name": "Rajakhera",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 942,
      "name": "Lar",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 943,
      "name": "Lal Gopalganj Nindaura",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 944,
      "name": "Muddebihal",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 945,
      "name": "Sirsaganj",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 946,
      "name": "Shahpura",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 947,
      "name": "Surandai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 948,
      "name": "Sangole",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 949,
      "name": "Pavagada",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 950,
      "name": "Tharad",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 951,
      "name": "Mansa",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 952,
      "name": "Umbergaon",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 953,
      "name": "Mavoor",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 954,
      "name": "Nalbari",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 955,
      "name": "Talaja",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 956,
      "name": "Malur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 957,
      "name": "Mangrulpir",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 958,
      "name": "Soro",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 959,
      "name": "Shahpura",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 960,
      "name": "Vadnagar",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 961,
      "name": "Raisinghnagar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 962,
      "name": "Sindhagi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 963,
      "name": "Sanduru",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 964,
      "name": "Sohna",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 965,
      "name": "Manavadar",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 966,
      "name": "Pihani",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 967,
      "name": "Safidon",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 968,
      "name": "Risod",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 969,
      "name": "Rosera",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 970,
      "name": "Sankari",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 971,
      "name": "Malpura",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 972,
      "name": "Sonamukhi",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 973,
      "name": "Shamsabad, Agra",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 974,
      "name": "Nokha",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 975,
      "name": "PandUrban Agglomeration",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 976,
      "name": "Mainaguri",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 977,
      "name": "Afzalpur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 978,
      "name": "Shirur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 979,
      "name": "Salaya",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 980,
      "name": "Shenkottai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 981,
      "name": "Pratapgarh",
      "state": "Tripura",
      "country": "Bharat"
  },
  {
      "id": 982,
      "name": "Vadipatti",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 983,
      "name": "Nagarkurnool",
      "state": "Telangana",
      "country": "Bharat"
  },
  {
      "id": 984,
      "name": "Savner",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 985,
      "name": "Sasvad",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 986,
      "name": "Rudrapur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 987,
      "name": "Soron",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 988,
      "name": "Sholingur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 989,
      "name": "Pandharkaoda",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 990,
      "name": "Perumbavoor",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 991,
      "name": "Maddur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 992,
      "name": "Nadbai",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 993,
      "name": "Talode",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 994,
      "name": "Shrigonda",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 995,
      "name": "Madhugiri",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 996,
      "name": "Tekkalakote",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 997,
      "name": "Seoni-Malwa",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 998,
      "name": "Shirdi",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 999,
      "name": "SUrban Agglomerationr",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1000,
      "name": "Terdal",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1001,
      "name": "Raver",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1002,
      "name": "Tirupathur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1003,
      "name": "Taraori",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 1004,
      "name": "Mukhed",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1005,
      "name": "Manachanallur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1006,
      "name": "Rehli",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1007,
      "name": "Sanchore",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1008,
      "name": "Rajura",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1009,
      "name": "Piro",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1010,
      "name": "Mudabidri",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1011,
      "name": "Vadgaon Kasba",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1012,
      "name": "Nagar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1013,
      "name": "Vijapur",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 1014,
      "name": "Viswanatham",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1015,
      "name": "Polur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1016,
      "name": "Panagudi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1017,
      "name": "Manawar",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1018,
      "name": "Tehri",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 1019,
      "name": "Samdhan",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1020,
      "name": "Pardi",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 1021,
      "name": "Rahatgarh",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1022,
      "name": "Panagar",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1023,
      "name": "Uthiramerur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1024,
      "name": "Tirora",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1025,
      "name": "Rangia",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 1026,
      "name": "Sahjanwa",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1027,
      "name": "Wara Seoni",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1028,
      "name": "Magadi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1029,
      "name": "Rajgarh (Alwar)",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1030,
      "name": "Rafiganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1031,
      "name": "Tarana",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1032,
      "name": "Rampur Maniharan",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1033,
      "name": "Sheoganj",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1034,
      "name": "Raikot",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 1035,
      "name": "Pauri",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 1036,
      "name": "Sumerpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1037,
      "name": "Navalgund",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1038,
      "name": "Shahganj",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1039,
      "name": "Marhaura",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1040,
      "name": "Tulsipur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1041,
      "name": "Sadri",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1042,
      "name": "Thiruthuraipoondi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1043,
      "name": "Shiggaon",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1044,
      "name": "Pallapatti",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1045,
      "name": "Mahendragarh",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 1046,
      "name": "Sausar",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1047,
      "name": "Ponneri",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1048,
      "name": "Mahad",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1049,
      "name": "Lohardaga",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 1050,
      "name": "Tirwaganj",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1051,
      "name": "Margherita",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 1052,
      "name": "Sundarnagar",
      "state": "Himachal Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1053,
      "name": "Rajgarh",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1054,
      "name": "Mangaldoi",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 1055,
      "name": "Renigunta",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1056,
      "name": "Longowal",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 1057,
      "name": "Ratia",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 1058,
      "name": "Lalgudi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1059,
      "name": "Shrirangapattana",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1060,
      "name": "Niwari",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1061,
      "name": "Natham",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1062,
      "name": "Unnamalaikadai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1063,
      "name": "PurqUrban Agglomerationzi",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1064,
      "name": "Shamsabad, Farrukhabad",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1065,
      "name": "Mirganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1066,
      "name": "Todaraisingh",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1067,
      "name": "Warhapur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1068,
      "name": "Rajam",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1069,
      "name": "Urmar Tanda",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 1070,
      "name": "Lonar",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1071,
      "name": "Powayan",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1072,
      "name": "P.N.Patti",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1073,
      "name": "Palampur",
      "state": "Himachal Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1074,
      "name": "Srisailam Project (Right Flank Colony) Township",
      "state": "Andhra Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1075,
      "name": "Sindagi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1076,
      "name": "Sandi",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1077,
      "name": "Vaikom",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 1078,
      "name": "Malda",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 1079,
      "name": "Tharangambadi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1080,
      "name": "Sakaleshapura",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1081,
      "name": "Lalganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1082,
      "name": "Malkangiri",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 1083,
      "name": "Rapar",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 1084,
      "name": "Mauganj",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1085,
      "name": "Todabhim",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1086,
      "name": "Srinivaspur",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1087,
      "name": "Murliganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1088,
      "name": "Reengus",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1089,
      "name": "Sawantwadi",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1090,
      "name": "Tittakudi",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1091,
      "name": "Lilong",
      "state": "Manipur",
      "country": "Bharat"
  },
  {
      "id": 1092,
      "name": "Rajaldesar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1093,
      "name": "Pathardi",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1094,
      "name": "Achhnera",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1095,
      "name": "Pacode",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1096,
      "name": "Naraura",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1097,
      "name": "Nakur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1098,
      "name": "Palai",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 1099,
      "name": "Morinda, India",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 1100,
      "name": "Manasa",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1101,
      "name": "Nainpur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1102,
      "name": "Sahaspur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1103,
      "name": "Pauni",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1104,
      "name": "Prithvipur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1105,
      "name": "Ramtek",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1106,
      "name": "Silapathar",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 1107,
      "name": "Songadh",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 1108,
      "name": "Safipur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1109,
      "name": "Sohagpur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1110,
      "name": "Mul",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1111,
      "name": "Sadulshahar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1112,
      "name": "Phillaur",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 1113,
      "name": "Sambhar",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1114,
      "name": "Prantij",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1115,
      "name": "Nagla",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 1116,
      "name": "Pattran",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 1117,
      "name": "Mount Abu",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1118,
      "name": "Reoti",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1119,
      "name": "Tenu dam-cum-Kathhara",
      "state": "Jharkhand",
      "country": "Bharat"
  },
  {
      "id": 1120,
      "name": "Panchla",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 1121,
      "name": "Sitarganj",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 1122,
      "name": "Pasighat",
      "state": "Arunachal Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1123,
      "name": "Motipur",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1124,
      "name": "O' Valley",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1125,
      "name": "Raghunathpur",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 1126,
      "name": "Suriyampalayam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1127,
      "name": "Qadian",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 1128,
      "name": "Rairangpur",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 1129,
      "name": "Silvassa",
      "state": "Dadra and Nagar Haveli",
      "country": "Bharat"
  },
  {
      "id": 1130,
      "name": "Nowrozabad (Khodargama)",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1131,
      "name": "Mangrol",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1132,
      "name": "Soyagaon",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1133,
      "name": "Sujanpur",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 1134,
      "name": "Manihari",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1135,
      "name": "Sikanderpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1136,
      "name": "Mangalvedhe",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1137,
      "name": "Phulera",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1138,
      "name": "Ron",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1139,
      "name": "Sholavandan",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1140,
      "name": "Saidpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1141,
      "name": "Shamgarh",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1142,
      "name": "Thammampatti",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1143,
      "name": "Maharajpur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1144,
      "name": "Multai",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1145,
      "name": "Mukerian",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 1146,
      "name": "Sirsi",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1147,
      "name": "Purwa",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1148,
      "name": "Sheohar",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1149,
      "name": "Namagiripettai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1150,
      "name": "Parasi",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1151,
      "name": "Lathi",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 1152,
      "name": "Lalganj",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1153,
      "name": "Narkhed",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1154,
      "name": "Mathabhanga",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 1155,
      "name": "Shendurjana",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1156,
      "name": "Peravurani",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1157,
      "name": "Mariani",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 1158,
      "name": "Phulpur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1159,
      "name": "Rania",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 1160,
      "name": "Pali",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1161,
      "name": "Pachore",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1162,
      "name": "Parangipettai",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1163,
      "name": "Pudupattinam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1164,
      "name": "Panniyannur",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 1165,
      "name": "Maharajganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1166,
      "name": "Rau",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1167,
      "name": "Monoharpur",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 1168,
      "name": "Mandawa",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1169,
      "name": "Marigaon",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 1170,
      "name": "Pallikonda",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1171,
      "name": "Pindwara",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1172,
      "name": "Shishgarh",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1173,
      "name": "Patur",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1174,
      "name": "Mayang Imphal",
      "state": "Manipur",
      "country": "Bharat"
  },
  {
      "id": 1175,
      "name": "Mhowgaon",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1176,
      "name": "Guruvayoor",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 1177,
      "name": "Mhaswad",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1178,
      "name": "Sahawar",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1179,
      "name": "Sivagiri",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1180,
      "name": "Mundargi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1181,
      "name": "Punjaipugalur",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1182,
      "name": "Kailasahar",
      "state": "Tripura",
      "country": "Bharat"
  },
  {
      "id": 1183,
      "name": "Samthar",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1184,
      "name": "Sakti",
      "state": "Chhattisgarh",
      "country": "Bharat"
  },
  {
      "id": 1185,
      "name": "Sadalagi",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1186,
      "name": "Silao",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1187,
      "name": "Mandalgarh",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1188,
      "name": "Loha",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1189,
      "name": "Pukhrayan",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1190,
      "name": "Padmanabhapuram",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1191,
      "name": "Belonia",
      "state": "Tripura",
      "country": "Bharat"
  },
  {
      "id": 1192,
      "name": "Saiha",
      "state": "Mizoram",
      "country": "Bharat"
  },
  {
      "id": 1193,
      "name": "Srirampore",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 1194,
      "name": "Talwara",
      "state": "Punjab",
      "country": "Bharat"
  },
  {
      "id": 1195,
      "name": "Puthuppally",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 1196,
      "name": "Khowai",
      "state": "Tripura",
      "country": "Bharat"
  },
  {
      "id": 1197,
      "name": "Vijaypur",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1198,
      "name": "Takhatgarh",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1199,
      "name": "Thirupuvanam",
      "state": "Tamil Nadu",
      "country": "Bharat"
  },
  {
      "id": 1200,
      "name": "Adra",
      "state": "West Bengal",
      "country": "Bharat"
  },
  {
      "id": 1201,
      "name": "Piriyapatna",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1202,
      "name": "Obra",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1203,
      "name": "Adalaj",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 1204,
      "name": "Nandgaon",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1205,
      "name": "Barh",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1206,
      "name": "Chhapra",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 1207,
      "name": "Panamattom",
      "state": "Kerala",
      "country": "Bharat"
  },
  {
      "id": 1208,
      "name": "Niwai",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1209,
      "name": "Bageshwar",
      "state": "Uttarakhand",
      "country": "Bharat"
  },
  {
      "id": 1210,
      "name": "Tarbha",
      "state": "Odisha",
      "country": "Bharat"
  },
  {
      "id": 1211,
      "name": "Adyar",
      "state": "Karnataka",
      "country": "Bharat"
  },
  {
      "id": 1212,
      "name": "Narsinghgarh",
      "state": "Madhya Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1213,
      "name": "Warud",
      "state": "Maharashtra",
      "country": "Bharat"
  },
  {
      "id": 1214,
      "name": "Asarganj",
      "state": "Bihar",
      "country": "Bharat"
  },
  {
      "id": 1215,
      "name": "Sarsod",
      "state": "Haryana",
      "country": "Bharat"
  },
  {
      "id": 1216,
      "name": "Gandhinagar",
      "state": "Gujarat",
      "country": "Bharat"
  },
  {
      "id": 1217,
      "name": "Kullu",
      "state": "Himachal Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1218,
      "name": "Manali",
      "state": "Himachal Praddesh",
      "country": "Bharat"
  },
  {
      "id": 1219,
      "name": "Mirzapur",
      "state": "Uttar Pradesh",
      "country": "Bharat"
  },
  {
      "id": 1220,
      "name": "Kota",
      "state": "Rajasthan",
      "country": "Bharat"
  },
  {
      "id": 1221,
      "name": "Dispur",
      "state": "Assam",
      "country": "Bharat"
  },
  {
      "id": 1222,
      "name": "Abbottabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1223,
      "name": "Adilpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1224,
      "name": "Ahmadpur East",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1225,
      "name": "Ahmadpur Sial",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1226,
      "name": "Akora",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1227,
      "name": "Aliabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1228,
      "name": "Alik Ghund",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1229,
      "name": "Alipur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1230,
      "name": "Alizai",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1231,
      "name": "Alpurai",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1232,
      "name": "Aman Garh",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1233,
      "name": "Amirabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1234,
      "name": "Arifwala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1235,
      "name": "Ashanagro Koto",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1236,
      "name": "Athmuqam",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1237,
      "name": "Attock City",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1238,
      "name": "Awaran",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1239,
      "name": "Baddomalhi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1240,
      "name": "Badin",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1241,
      "name": "Baffa",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1242,
      "name": "Bagarji",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1243,
      "name": "Bagh",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1244,
      "name": "Bahawalnagar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1245,
      "name": "Bahawalnagar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1246,
      "name": "Bahawalpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1247,
      "name": "Bakhri Ahmad Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1248,
      "name": "Bandhi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1249,
      "name": "Bannu",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1250,
      "name": "Barishal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1251,
      "name": "Barkhan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1252,
      "name": "Basirpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1253,
      "name": "Basti Dosa",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1254,
      "name": "Bat Khela",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1255,
      "name": "Battagram",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1256,
      "name": "Begowala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1257,
      "name": "Bela",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1258,
      "name": "Berani",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1259,
      "name": "Bhag",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1260,
      "name": "Bhakkar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1261,
      "name": "Bhalwal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1262,
      "name": "Bhan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1263,
      "name": "Bhawana",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1264,
      "name": "Bhera",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1265,
      "name": "Bhimbar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1266,
      "name": "Bhiria",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1267,
      "name": "Bhit Shah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1268,
      "name": "Bhopalwala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1269,
      "name": "Bozdar Wada",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1270,
      "name": "Bulri",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1271,
      "name": "Būrewāla",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1272,
      "name": "Chak",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1273,
      "name": "Chak Azam Sahu",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1274,
      "name": "Chak Five Hundred Seventy-five",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1275,
      "name": "Chak Jhumra",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1276,
      "name": "Chak One Hundred Twenty Nine Left",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1277,
      "name": "Chak Thirty-one -Eleven Left",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1278,
      "name": "Chak Two Hundred Forty-nine Thal Development Authority",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1279,
      "name": "Chakwal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1280,
      "name": "Chaman",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1281,
      "name": "Chamber",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1282,
      "name": "Charsadda",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1283,
      "name": "Chawinda",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1284,
      "name": "Chenab Nagar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1285,
      "name": "Cherat Cantonement",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1286,
      "name": "Chhor",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1287,
      "name": "Chichawatni",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1288,
      "name": "Chilas",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1289,
      "name": "Chiniot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1290,
      "name": "Chishtian",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1291,
      "name": "Chitral",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1292,
      "name": "Choa Saidan Shah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1293,
      "name": "Chowki Jamali",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1294,
      "name": "Chuchar-kana Mandi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1295,
      "name": "Chuhar Jamali",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1296,
      "name": "Chunian",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1297,
      "name": "Dadhar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1298,
      "name": "Dadu",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1299,
      "name": "Daggar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1300,
      "name": "Daira Din Panah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1301,
      "name": "Dajal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1302,
      "name": "Dalbandin",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1303,
      "name": "Dandot RS",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1304,
      "name": "Daromehar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1305,
      "name": "Darya Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1306,
      "name": "Darya Khan Marri",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1307,
      "name": "Daska Kalan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1308,
      "name": "Dasu",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1309,
      "name": "Daud Khel",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1310,
      "name": "Daulatpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1311,
      "name": "Daultala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1312,
      "name": "Daur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1313,
      "name": "Dera Allahyar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1314,
      "name": "Dera Bugti",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1315,
      "name": "Dera Ghazi Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1316,
      "name": "Dera Ismail Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1317,
      "name": "Dera Murad Jamali",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1318,
      "name": "Dhanot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1319,
      "name": "Dhaunkal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1320,
      "name": "Dhoro Naro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1321,
      "name": "Digri",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1322,
      "name": "Dijkot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1323,
      "name": "Dinan Bashnoian Wala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1324,
      "name": "Dinga",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1325,
      "name": "Dipalpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1326,
      "name": "Diplo",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1327,
      "name": "Doaba",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1328,
      "name": "Dokri",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1329,
      "name": "Duki",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1330,
      "name": "Dullewala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1331,
      "name": "Dunga Bunga",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1332,
      "name": "Dunyapur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1333,
      "name": "Eidgah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1334,
      "name": "Eminabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1335,
      "name": "Faisalabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1336,
      "name": "Faqirwali",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1337,
      "name": "Faruka",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1338,
      "name": "Fazilpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1339,
      "name": "Fort Abbas",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1340,
      "name": "Gadani",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1341,
      "name": "Gakuch",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1342,
      "name": "Gambat",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1343,
      "name": "Gandava",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1344,
      "name": "Garh Maharaja",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1345,
      "name": "Garhi Khairo",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1346,
      "name": "Garhiyasin",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1347,
      "name": "Gharo",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1348,
      "name": "Ghauspur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1349,
      "name": "Ghotki",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1350,
      "name": "Gilgit",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1351,
      "name": "Gojra",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1352,
      "name": "Goth Garelo",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1353,
      "name": "Goth Phulji",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1354,
      "name": "Goth Radhan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1355,
      "name": "Gujar Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1356,
      "name": "Gujranwala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1357,
      "name": "Gujrat",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1358,
      "name": "Gulishah Kach",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1359,
      "name": "Gwadar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1360,
      "name": "Hadali",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1361,
      "name": "Hafizabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1362,
      "name": "Hala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1363,
      "name": "Hangu",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1364,
      "name": "Haripur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1365,
      "name": "Harnai",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1366,
      "name": "Harnoli",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1367,
      "name": "Harunabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1368,
      "name": "Hasilpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1369,
      "name": "Hattian Bala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1370,
      "name": "Haveli Lakha",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1371,
      "name": "Havelian",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1372,
      "name": "Hazro City",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1373,
      "name": "Hingorja",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1374,
      "name": "Hujra Shah Muqim",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1375,
      "name": "Hyderabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1376,
      "name": "Islamabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1377,
      "name": "Islamkot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1378,
      "name": "Jacobabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1379,
      "name": "Jahanian Shah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1380,
      "name": "Jalalpur Jattan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1381,
      "name": "Jalalpur Pirwala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1382,
      "name": "Jampur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1383,
      "name": "Jamshoro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1384,
      "name": "Jand",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1385,
      "name": "Jandiala Sher Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1386,
      "name": "Jaranwala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1387,
      "name": "Jati",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1388,
      "name": "Jatoi Shimali",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1389,
      "name": "Jauharabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1390,
      "name": "Jhang City",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1391,
      "name": "Jhang Sadr",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1392,
      "name": "Jhawarian",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1393,
      "name": "Jhelum",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1394,
      "name": "Jhol",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1395,
      "name": "Jiwani",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1396,
      "name": "Johi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1397,
      "name": "Jām Sāhib",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1398,
      "name": "Kabirwala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1399,
      "name": "Kadhan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1400,
      "name": "Kahna Nau",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1401,
      "name": "Kahror Pakka",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1402,
      "name": "Kahuta",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1403,
      "name": "Kakad Wari Dir Upper",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1404,
      "name": "Kalabagh",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1405,
      "name": "Kalaswala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1406,
      "name": "Kalat",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1407,
      "name": "Kaleke Mandi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1408,
      "name": "Kallar Kahar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1409,
      "name": "Kalur Kot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1410,
      "name": "Kamalia",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1411,
      "name": "Kamar Mushani",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1412,
      "name": "Kambar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1413,
      "name": "Kamoke",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1414,
      "name": "Kamra",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1415,
      "name": "Kandhkot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1416,
      "name": "Kandiari",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1417,
      "name": "Kandiaro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1418,
      "name": "Kanganpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1419,
      "name": "Karachi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1420,
      "name": "Karak",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1421,
      "name": "Karaundi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1422,
      "name": "Kario Ghanwar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1423,
      "name": "Karor",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1424,
      "name": "Kashmor",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1425,
      "name": "Kasur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1426,
      "name": "Keshupur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1427,
      "name": "Keti Bandar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1428,
      "name": "Khadan Khak",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1429,
      "name": "Khadro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1430,
      "name": "Khairpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1431,
      "name": "Khairpur Mir’s",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1432,
      "name": "Khairpur Nathan Shah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1433,
      "name": "Khairpur Tamewah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1434,
      "name": "Khalabat",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1435,
      "name": "Khandowa",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1436,
      "name": "Khanewal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1437,
      "name": "Khangah Dogran",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1438,
      "name": "Khangarh",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1439,
      "name": "Khanpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1440,
      "name": "Khanpur Mahar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1441,
      "name": "Kharan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1442,
      "name": "Kharian",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1443,
      "name": "Khewra",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1444,
      "name": "Khurrianwala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1445,
      "name": "Khushāb",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1446,
      "name": "Khuzdar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1447,
      "name": "Kohat",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1448,
      "name": "Kohlu",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1449,
      "name": "Kot Addu",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1450,
      "name": "Kot Diji",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1451,
      "name": "Kot Ghulam Muhammad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1452,
      "name": "Kot Malik Barkhurdar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1453,
      "name": "Kot Mumin",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1454,
      "name": "Kot Radha Kishan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1455,
      "name": "Kot Rajkour",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1456,
      "name": "Kot Samaba",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1457,
      "name": "Kot Sultan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1458,
      "name": "Kotli",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1459,
      "name": "Kotli Loharan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1460,
      "name": "Kotri",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1461,
      "name": "Kulachi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1462,
      "name": "Kundian",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1463,
      "name": "Kunjah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1464,
      "name": "Kunri",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1465,
      "name": "Lachi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1466,
      "name": "Ladhewala Waraich",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1467,
      "name": "Lahore",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1468,
      "name": "Lakhi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1469,
      "name": "Lakki",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1470,
      "name": "Lala Musa",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1471,
      "name": "Lalian",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1472,
      "name": "Landi Kotal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1473,
      "name": "Larkana",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1474,
      "name": "Layyah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1475,
      "name": "Liliani",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1476,
      "name": "Lodhran",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1477,
      "name": "Loralai",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1478,
      "name": "Mach",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1479,
      "name": "Madeji",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1480,
      "name": "Mailsi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1481,
      "name": "Malakand",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1482,
      "name": "Malakwal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1483,
      "name": "Malakwal City",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1484,
      "name": "Malir Cantonment",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1485,
      "name": "Mamu Kanjan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1486,
      "name": "Mananwala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1487,
      "name": "Mandi Bahauddin",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1488,
      "name": "Mangla",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1489,
      "name": "Mankera",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1490,
      "name": "Mansehra",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1491,
      "name": "Mardan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1492,
      "name": "Mastung",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1493,
      "name": "Matiari",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1494,
      "name": "Matli",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1495,
      "name": "Mehar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1496,
      "name": "Mehmand Chak",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1497,
      "name": "Mehrabpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1498,
      "name": "Mian Channun",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1499,
      "name": "Mianke Mor",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1500,
      "name": "Mianwali",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1501,
      "name": "Minchianabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1502,
      "name": "Mingora",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1503,
      "name": "Miran Shah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1504,
      "name": "Miro Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1505,
      "name": "Mirpur Bhtoro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1506,
      "name": "Mirpur Khas",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1507,
      "name": "Mirpur Mathelo",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1508,
      "name": "Mirpur Sakro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1509,
      "name": "Mirwah Gorchani",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1510,
      "name": "Mitha Tiwana",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1511,
      "name": "Mithi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1512,
      "name": "Moro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1513,
      "name": "Moza Shahwala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1514,
      "name": "Multan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1515,
      "name": "Muridke",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1516,
      "name": "Murree",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1517,
      "name": "Musa Khel Bazar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1518,
      "name": "Mustafābād",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1519,
      "name": "Muzaffargarh",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1520,
      "name": "Muzaffarābād",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1521,
      "name": "Nabisar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1522,
      "name": "Nankana Sahib",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1523,
      "name": "Narang Mandi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1524,
      "name": "Narowal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1525,
      "name": "Nasirabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1526,
      "name": "Naudero",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1527,
      "name": "Naukot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1528,
      "name": "Naushahra Virkan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1529,
      "name": "Naushahro Firoz",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1530,
      "name": "Nawabshah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1531,
      "name": "Nazir Town",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1532,
      "name": "New Bādāh",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1533,
      "name": "New Mirpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1534,
      "name": "Noorabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1535,
      "name": "Nowshera",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1536,
      "name": "Nowshera Cantonment",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1537,
      "name": "Nushki",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1538,
      "name": "Okara",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1539,
      "name": "Ormara",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1540,
      "name": "Pabbi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1541,
      "name": "Pad Idan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1542,
      "name": "Paharpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1543,
      "name": "Pakpattan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1544,
      "name": "Panjgur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1545,
      "name": "Pano Aqil",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1546,
      "name": "Parachinar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1547,
      "name": "Pasni",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1548,
      "name": "Pasrur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1549,
      "name": "Pattoki",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1550,
      "name": "Peshawar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1551,
      "name": "Phalia",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1552,
      "name": "Pind Dadan Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1553,
      "name": "Pindi Bhattian",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1554,
      "name": "Pindi Gheb",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1555,
      "name": "Pir Jo Goth",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1556,
      "name": "Pir Mahal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1557,
      "name": "Pishin",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1558,
      "name": "Pithoro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1559,
      "name": "Qadirpur Ran",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1560,
      "name": "Qila Abdullah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1561,
      "name": "Qila Saifullah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1562,
      "name": "Quetta",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1563,
      "name": "Rahim Yar Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1564,
      "name": "Raiwind",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1565,
      "name": "Raja Jang",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1566,
      "name": "Rajanpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1567,
      "name": "Rajo Khanani",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1568,
      "name": "Ranipur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1569,
      "name": "Rasulnagar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1570,
      "name": "Ratodero",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1571,
      "name": "Rawala Kot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1572,
      "name": "Rawalpindi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1573,
      "name": "Renala Khurd",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1574,
      "name": "Risalpur Cantonment",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1575,
      "name": "Rohri",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1576,
      "name": "Rojhan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1577,
      "name": "Rustam",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1578,
      "name": "Saddiqabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1579,
      "name": "Sahiwal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1580,
      "name": "Sahiwal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1581,
      "name": "Saidu Sharif",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1582,
      "name": "Sakrand",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1583,
      "name": "Samaro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1584,
      "name": "Sambrial",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1585,
      "name": "Sanghar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1586,
      "name": "Sangla Hill",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1587,
      "name": "Sanjwal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1588,
      "name": "Sann",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1589,
      "name": "Sarai Alamgir",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1590,
      "name": "Sarai Naurang",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1591,
      "name": "Sarai Sidhu",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1592,
      "name": "Sargodha",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1593,
      "name": "Sehwan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1594,
      "name": "Setharja Old",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1595,
      "name": "Shabqadar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1596,
      "name": "Shahdad Kot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1597,
      "name": "Shahdadpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1598,
      "name": "Shahkot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1599,
      "name": "Shahpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1600,
      "name": "Shahpur Chakar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1601,
      "name": "Shahr Sultan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1602,
      "name": "Shakargarh",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1603,
      "name": "Sharqpur Sharif",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1604,
      "name": "Shekhupura",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1605,
      "name": "Shikarpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1606,
      "name": "Shingli Bala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1607,
      "name": "Shinpokh",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1608,
      "name": "Shorkot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1609,
      "name": "Shujaabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1610,
      "name": "Sialkot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1611,
      "name": "Sibi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1612,
      "name": "Sillanwali",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1613,
      "name": "Sinjhoro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1614,
      "name": "Skardu",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1615,
      "name": "Sobhodero",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1616,
      "name": "Sodhri",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1617,
      "name": "Sohbatpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1618,
      "name": "Sukheke Mandi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1619,
      "name": "Sukkur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1620,
      "name": "Surab",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1621,
      "name": "Surkhpur",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1622,
      "name": "Swabi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1623,
      "name": "Sīta Road",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1624,
      "name": "Talagang",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1625,
      "name": "Talamba",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1626,
      "name": "Talhar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1627,
      "name": "Tandlianwala",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1628,
      "name": "Tando Adam",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1629,
      "name": "Tando Allahyar",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1630,
      "name": "Tando Bago",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1631,
      "name": "Tando Jam",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1632,
      "name": "Tando Mitha Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1633,
      "name": "Tando Muhammad Khan",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1634,
      "name": "Tangi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1635,
      "name": "Tangwani",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1636,
      "name": "Tank",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1637,
      "name": "Taunsa",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1638,
      "name": "Thal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1639,
      "name": "Tharu Shah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1640,
      "name": "Thatta",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1641,
      "name": "Thul",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1642,
      "name": "Timargara",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1643,
      "name": "Toba Tek Singh",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1644,
      "name": "Topi",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1645,
      "name": "Turbat",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1646,
      "name": "Ubauro",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1647,
      "name": "Umarkot",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1648,
      "name": "Upper Dir",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1649,
      "name": "Usta Muhammad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1650,
      "name": "Uthal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1651,
      "name": "Utmanzai",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1652,
      "name": "Vihari",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1653,
      "name": "Wana",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1654,
      "name": "Warah",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1655,
      "name": "Wazirabad",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1656,
      "name": "Yazman",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1657,
      "name": "Zafarwal",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1658,
      "name": "Zahir Pir",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1659,
      "name": "Zaida",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1660,
      "name": "Zhob",
      "state": "",
      "country": "Pakistan"
  },
  {
      "id": 1661,
      "name": "Ziarat",
      "state": "",
      "country": "Pakistan"
  }
];

export default cities;