const places = [{
    "name": "Prahaladha",
    "type": "Bhakta",
    "god": "Vishnu",
    "epoch": "Satya",
    "year": null,
    "city": "Ahobilam",
    "state": "Andhra Pradesh",
    "country": "Bharat",
    "coordinates": [15.133, 78.716],
    "content": `Prahlad (IAST: Prahlāda) was a king, the son of Hiranyakashipu and Kayadhu, and the father of Virochana. He belonged to the Kashyapa gotra. He is described as a saintly boy from the Puranas known for his piety and bhakti to Vishnu. Despite the abusive nature of his father, Hiranyakashipu, he continued his devotion towards God Vishnu.[2] He is considered to be a mahājana, or great devotee, by followers of Vaishnava traditions and is of special importance to devotees of Narasimha avatar. A treatise is accredited to him in the Bhagavata Purana in which Prahlāda describes the process of loving worship to Vishnu. The majority of stories in the Puranas are based on the activities of Prahlāda as a young boy, and he is usually depicted as such in paintings and illustrations.`
},
{
    "name": "Markandeya",
    "type": "Bhakta",
    "god": "Shiva",
    "epoch": "Satya",
    "year": null,
    "city": "Yamunotri",
    "state": "Uttarakhand",
    "country": "Bharat",
    "coordinates": [31.010, 78.450],
    "content": `Markandeya (Sanskrit: मार्कण्‍डेय)( IAST: Mārkaṇḍeya) is an ancient rishi (sage) born in the clan of Bhrigu Rishi. The Markandeya Purana especially, comprises a dialogue between Markandeya and a sage called Jaimini, and a number of chapters in the Bhagavata Purana are dedicated to his conversations and prayers.[1] He is also mentioned in the Mahabharata. Markandeya is venerated within all mainstream Hindu traditions.

    Today, Markandeya Tirtha, where the sage Markandeya wrote the Markandeya Purana is situated on a trekking route to the Yamunotri Shrine in the Uttarkashi district, Uttarakhand.[2]
    One legend relates the story of how Shiva protected Markandeya from the clutches of death, personified as Yama.

The great sage Mrikandu rishi and his wife Marudmati worshipped Shiva and sought from him the boon of begetting a son. As a result, he was given the choice of either a righteous son, but with a short life on earth or a child of low intelligence but with a long life. Mrikandu rishi chose the former, and was blessed with Markandeya, an exemplary son, destined to die at the age of 16.`
},
{
    "name": "Kalidas",
    "type": "Bhakta",
    "god": "Shakti",
    "epoch": "Kali",
    "year": null,
    "city": "Ujjain",
    "state": "Madhya Pradesh",
    "country": "Bharat",
    "coordinates": [23.170, 75.790],
    "content":`Kālidāsa (Devanagari: कालिदास; fl. 4th–5th century CE) was a Classical Sanskrit author who is often considered ancient India's greatest playwright and dramatist. His plays and poetry are primarily based on the Vedas, the Rāmāyaṇa, the Mahābhārata and the Purāṇas.[1] His surviving works consist of three plays, two epic poems and two shorter poems.

    Much about his life is unknown except what can be inferred from his poetry and plays.[2] His works cannot be dated with precision, but they were most likely authored before the 5th century CE..`
},
{
    "name": "Pothana",
    "type": "Bhakta",
    "god": "Shiva",
    "epoch": "Kali",
    "year": null,
    "city": "Bammera",
    "state": "Telangana",
    "country": "Bharat",
    "coordinates": [18.000, 79.580],
    "content": `Bammera Pothana (Telugu: బమ్మెర పోతన) (1450–1510) was an Telugu poet best known for his translation of the Bhagavata Purana from Sanskrit to Telugu.
    He was a Telugu and Sanskrit Scholar.[1] His work, Sri Maha Bhagavatamu, is popularly called as Pothana Bhagavatam in Telugu.
    Pothana was born into a Niyogi Brahmin family[3] at Bammera Village, in the Jangaon District of present Telangana state. 
    His father was Kesana and his mother Lakkamamba. He was considered to be a 'Sahaja Kavi' (natural poet), needing no teacher.
    He was known to be very polite and was an agriculturist by occupation. Though he was a great scholar, he never hesitated 
    to work in the agricultural fields.`
},
{
    "name": "Thyagaraja",
    "type": "Bhakta",
    "god": "Vishnu",
    "epoch": "Kali",
    "year": null,
    "city": "Thiruvarur",
    "state": "Tamil Nadu",
    "country": "Bharat",
    "coordinates": [15.133, 78.716],
    "content": `Tyagaraja (4 May 1767 – 6 January 1847), also known as Tyāgayya, was a composer of Carnatic music, a form of Indian classical music. He was prolific and highly influential in the development of India's classical music tradition. Tyagaraja and his contemporaries, Shyama Shastri and Muthuswami Dikshitar, are regarded as the Trinity of Carnatic music. Tyagaraja composed thousands of devotional compositions, most in Telugu and in praise of Lord Rama, many of which remain popular today.[3] Of special mention are five of his compositions called the Pancharatna Kritis (English: "five gems"), which are often sung in programs in his honour.

    Tyagaraja saw the reigns of four kings of the Maratha dynasty — Tulaja II (1763–1787), Amarasimha (1787–1798), Serfoji II (1798–1832) and Sivaji II (1832–1855),[4] although he served none of them.`
},
{
    "name": "Arjuna",
    "type": "Bhakta",
    "god": "Shakti",
    "epoch": "Dwapara",
    "year": null,
    "city": "Vijayawada",
    "state": "Andhra Pradesh",
    "country": "Bharat",
    "coordinates": [16.850, 80.380],
    "content": `Arjuna (Sanskrit: अर्जुन, IAST: Arjuna), also known as Partha and Dhananjaya, is one of the major characters of the Indian epic Mahabharata and also appears in other ancient Hindu texts including the Bhagavata Purana. In the epic, he is the third among Pandavas, the five sons of Pandu. The family formed part of the royal line of the Kuru Kingdom. In the Mahabharata War, Arjuna was a key warrior from the Pandava side and slew many warriors including his nemesis, Karna unfairly. Before the beginning of the war, his mentor, Krishna, taught him the knowledge of Bhagavad Gita.[1]

    Arjuna was born when Indra, the god of rain, blessed Kunti and Pandu with a son. From childhood, Arjuna was a brilliant student and was favoured by his beloved teacher, Drona. Arjuna is depicted as a skilled archer, winning the hands of Draupadi, who married the five brothers because of Kunti's misunderstanding. Arjuna is twice exiled, first for breaking a pact with his brothers; and secondly together with them when his oldest brother was tricked into gambling away the throne.
    
    Some notable incidents during the first exile were Arjuna's marriages with princesses Ulupi, Chitrāngadā and Subhadra. From his four wives, Arjuna had four sons, one from each wife — Shrutakarma, Iravan, Babhruvahana and Abhimanyu. During his second exile, Arjuna gained many celestial weapons. Despite being a powerful warrior, Arjuna was also skilled in music and dance. At the end of the epic, Pandavas, accompanied by Draupadi, retired to the Himalayas. Arjuna was the fourth one to fall on the way.`
},
{
    "name": "Vibishana",
    "type": "Bhakta",
    "god": "Vishnu",
    "epoch": "Treta",
    "year": null,
    "city": "Colombo",
    "state": null,
    "country": "Sri Lanka",
    "coordinates": [6.927, 79.861],
    "content": `Vibhishana (IAST: Vibhīṣaṇa or Bibhishan) is the younger brother of Ravana, the king of Lanka in the ancient Indian epic Ramayana.[1] Though a Rakshasa himself, Vibhishana deserted Ravana and joined Rama's army. Later, when Rama defeated Ravana, Rama crowned Vibhishana as the king of Lanka before returning to Ayodhya.
    Vibhishana had a sattvic (pure) mind and a sattvic heart. From his early childhood, he spent all his time meditating on the name of the Lord. Eventually, Brahma appeared and offered him any boon he wanted. Vibhishana said that the only thing he wanted was to have his mind fixed at the feet of the Lord as pure as lotus leaves (charan kamal). He prayed that he should be given the strength by which he would always be at the feet of the Lord and that he would receive the darshan (holy sight) of Lord Vishnu. This prayer was fulfilled when he was able to give up all his wealth and family, and join Rama, who was Avatar of Lord Vishnu (God incarnate).`
},
{
    "name": "Ambarisha",
    "type": "Bhakta",
    "god": "Vishnu",
    "epoch": "Satya",
    "year": null,
    "city": "Mathura",
    "state": "Uttar Pradesh",
    "country": "Bharat",
    "coordinates": [25.114, 81.535],
    "content": `Ambarisha (Sanskrit: अम्बरीषः, Ambarīṣa) was an Ikshvaku king and son of Mandhata.[1] He is believed to have conquered the whole world in a week.[2] He lived in Treta Yuga.
    According to the Bhagavata Purana, he was a great devotee of Vishnu and adhered firmly to the truth. Being pleased with his devotion, Vishnu had given his Sudarshana Chakra to Ambarisha, who worshiped the Lord's weapon with great devotion. Once, Ambarisha performed the Dvadashi Vrata at Vrindavan, which required that the king must start a fast on Ekadashi and break it at the start of Dvadasi and feed all the people. As the moment of breaking the fast was drawing near, the mighty sage Durvasa arrived and was received with all honours by Ambarisha. Durvasa agreed to the king's request to be his honoured guest, and asked the king to wait until he finished his bath in the Yamuna river and returned. As the auspicious moment approached when the king had to break his fast to fulfill the vow of the vrata, Durvasa did not turn up. On the advice of his priests, the king broke his fast by taking a little water and waited for the arrival of sage Durvasa to offer him food.

Durvasa, who was well known for his short temper, felt that Ambarisha had violated the respect due to a guest by breaking his fast before the guest had taken his meal, and in his rage created a demon to kill Ambarisha, out of a strand of his hair. Because Ambarisha was a great devotee, the Sudarshana Chakra intervened, destroyed the demon and started chasing Durvasa himself. Durvasa went to Brahma and Shiva for protection. Both pleaded their inability to save him. He went to Vishnu himself, who said that he could do nothing as he was bound by the blemishless devotion of Ambarisha and suggested to the sage to seek the pardon of the king. Durvasa went to Ambarisha, who prayed to Lord Vishnu to recall the Sudarshana and save Durvasa. Thus Durvasa's life was spared.`
},
{
    "name": "Morya Gosavi",
    "type": "Bhakta",
    "god": "Ganesha",
    "epoch": "Kali",
    "year": 1375,
    "city": "Bidar",
    "state": "Karnataka",
    "country": "Bharat",
    "coordinates": [17.900, 77.500],
    "content": `Morya Gosavi or Moraya Gosavi (Morayā Gosāvi) alias Moroba Gosavi was a prominent saint of the Hindu Ganapatya sect, which considers the elephant-faced god Ganesha as the Supreme Being. Morya Gosavi is considered the chief spiritual progenitor of the Ganapatyas and has been described as the "most famous devotee" of Ganesha.[1]

    The lifetime of Morya Gosavi is speculated between the 13th to 17th century. Numerous legends recall his life. Morya became devoted to Ganesha when he started visiting the Morgaon temple of Ganesha. It is believed that due to the hindrance in Morya's services to the god in the popular Ganesha shrine, Ganesha told Morya that he would appear in Chinchwad for Morya to worship, so Morya moved from Morgaon to Chinchwad, where Morya built a Ganesha temple. Consequently, Morya took sanjeevan samadhi by burying himself alive in his tomb.
    
    Morya had a son called Chintamani, venerated as a living incarnation of Ganesha and addressed as Dev (god). Chintamani was succeeded by six more Devs. The tomb of Morya Gosavi and the Ganesha temple at Chinchwad still attract many Ganesha devotees.`
},
{
    "name": "Bhishma",
    "type": "Bhakta",
    "god": "Vishnu",
    "epoch": "Dwapara",
    "year": null,
    "city": "Kurukshetra",
    "state": "Haryana",
    "country": "Bharat",
    "coordinates": [29.965, 76.837],
    "content": `Bhishma (Sanskrit: भीष्‍म, IAST: Bhīṣma, lit. 'terrible'), also known as Pitamaha, Gangaputra and Devavrata, was a statesman of Kuru Kingdom and one of the most powerful warriors in the Hindu epic Mahabharata. He was the eighth and only surviving son of the Kuru King Shantanu and the river goddess Ganga. He was related to both the Pandavas and the Kauravas through his half-brother, Vichitravirya.

    Originally named Devavrata, he was made the heir-apparent of the kingdom. However, he ceded his rights for his father's happiness and took the vow of lifelong celibacy. Because of this terrible oath, he came to be known as Bhishma and was blessed to live as long as he wanted. He played a major role in the political affairs of the Kuru kingdom and participated in the Kurukshetra War from the side of Kauravas. On the tenth day of the war, the Pandava prince Arjuna, with the help of Shikhandi, pierced Bhishma with numerous arrows and paralysed him on a bed of arrows. After spending fifty-one nights on the arrow bed, Bhishma left his body on the Uttarayana (winter solstice). Before his death, he handed down the Vishnu Sahasranama to the emperor Yudhishtira.
    
    Bhishma has a large significance in the Hindu culture. Each year his death anniversary is celebrated as Bhishma Ashtami, which falls on the eighth lunar day of the Shukla (light) half of Magha (January–February) month.`
}];

export default places;