export const FETCH_PLACES = 'FETCH_PLACES';
export const FETCH_PLACES_SUCCESS = 'FETCH_PLACES_SUCCESS';
export const FETCH_PLACES_ERROR = 'FETCH_PLACES_ERROR';
export const ADD_TO_FETCHED_PLACES = 'ADD_TO_FETCHED_PLACES';
export const UPDATE_FETCHED_PLACE = 'UPDATE_FETCHED_PLACE';
export const UPDATE_PLACES = 'UPDATE_PLACES';
export const SHOW_PLACE = 'SHOW_PLACE';
export const HIDE_PLACE = 'HIDE_PLACE';
export const FILTER_PLACES_BY_GOD = 'FILTER_PLACES_BY_GOD';
export const FILTER_BHAKTAS_BY_NAME = 'FILTER_BHAKTAS_BY_NAME';
export const FILTER_BHAKTAS = 'FILTER_BHAKTAS';
export const RESET_BHAKTAS = 'RESET_BHAKTAS';
export const CREATE_PLACE_SUCCESS = 'CREATE_PLACE_SUCCESS'
export const CREATE_PLACE_ERROR = 'CREATE_PLACE_ERROR'
export const FETCH_CITIES = 'FETCH_CITIES';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const DELETE_FILES = 'DELETE_FILES';
export const SET_FILES = 'SET_FILES';
export const API_ERROR = 'API_ERROR';
export const LOAD_STATUS = 'LOAD_STATUS';
export const ADD_LINK = 'ADD_LINK';
export const DELETE_LINK = 'DELETE_LINK';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_LINKS = 'SET_LINKS';
export const DELETE_LINKS = 'DELETE_LINKS';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const SET_VIEW = 'SET_VIEW';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_ABOUT = 'SET_ABOUT';
export const DELETE_ABOUT = 'DELETE_ABOUT';
export const FETCH_FILTER = 'FETCH_FILTER';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const UPDATE_GOD_FILTER = 'UPDATE_GOD_FILTER';
export const UPDATE_TYPE_FILTER = 'UPDATE_TYPE_FILTER';
export const UPDATE_YUGA_FILTER = 'UPDATE_YUGA_FILTER';
export const UPDATE_NAME_FILTER = 'UPDATE_NAME_FILTER';
export const UPDATE_CITY_FILTER = 'UPDATE_CITY_FILTER';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_BHAKTA = 'SET_BHAKTA';
export const FETCH_BHAKTA_ERROR = 'FETCH_BHAKTA_ERROR';
export const FETCH_BHAKTA_SUCCESS = 'FETCH_BHAKTA_SUCCESS';