import {
      SET_ABOUT,
      DELETE_ABOUT,
      SET_BHAKTA
    } from '../actions/types';
    
    //const INITIAL_STATE = {about:{english:null, devanagari: null, telugu: null, tamil: null, kannada: null}};

    const INITIAL_STATE = {};
    
    export default (state = INITIAL_STATE, action) => {
      switch (action.type) {
          case SET_ABOUT:
            return {...state, about:{...state.about, [action.payload.language]:action.payload.content}};
          case DELETE_ABOUT:
            return {...state, about:{english:null, devanagari: null, telugu: null, tamil: null, kannada: null}};
          case SET_BHAKTA:
            return {...state, ...action.payload}
          default:
            return state;
      }
    };