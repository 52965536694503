import { Box, Card, IconButton } from "@mui/material";
import FlexBox from "./flexbox/FlexBox";
import { H6, Paragraph } from "./Typography";
import TempleHinduIcon from '@mui/icons-material/TempleHindu';
import React from "react"; // -----------------------------------------------------

// -----------------------------------------------------
const BhaktaPreview = ({ bhakta }) => {
  return (
    
      <Box sx={{"marginBottom":2}}>
        {false && <FlexBox alignItems="center" gap={1} mb={1}>
          <TempleHinduIcon/>
          <H6 fontSize={12}>Office</H6>
        </FlexBox>}

        {false && <Paragraph fontSize={10} fontWeight={500} lineHeight={1.8}>
        Prahlada a saintly boy from the Puranas known for his piety and bhakti to Vishnu, despite the attempts of his father, Hiranyakashipu, to change h]im Hiranyakashipu, an evil king who had been granted a boon that he could not be killed by man or animal, day or night, inside or outside. Despite several warnings from his father Hiranyakashipu, Prahlada continues to worship Vishnu instead His father then decided to commit filicide and poison him, but he survived. Then he trampled the boy with elephants, but he lived. Then he put him in a room with venomous snakes, and they made a bed for him with their bodies. Holika, the sister of Hiranyakashipu, was blessed in that she could not be hurt by fire. Hiranyakashipu puts Prahlada on the lap of Holika as she sits on a pyre. Prahlada prays to Vishnu to keep him safe. Holika burns to death as Prahlada is unscathed. This event is celebrated as the Hindu festival of Holi.holi famous hindu festival also started after this story in Prahladpuri Temple. in Multan, Pakistan After tolerating abuse from Hiranyakashipu, Prahlada is eventually saved by Narasimha, the half-man, half-lion avatar, who kills the king at the entrance to his home at dusk.
        </Paragraph>}
        <Paragraph fontSize={10} fontWeight={500} lineHeight={1.8}>
          {bhakta.about[Object.keys(bhakta.about)[0]][0].replace( /(<([^>]+)>)/ig, '').substring(0, 200) + "..."}
        </Paragraph>
      </Box>

    
  );
};

export default BhaktaPreview;
