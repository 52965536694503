import React from 'react';
import { useState } from "react";
import { Card, Grid, IconButton, MenuItem, Select, Stack, styled, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Box } from "@mui/system";
import CircleIcon from '@mui/icons-material/Circle';
import Person2Icon from '@mui/icons-material/Person2';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import FlexBox from "./flexbox/FlexBox";
import { H4, H5, H6, Tiny } from "./Typography";
import Ganesha from '../icons/Ganesha';
import Shiva from '../icons/Shiva';
import Vishnu from '../icons/Vishnu';
import Skanda from '../icons/Skanda';
import Shakti from '../icons/Shakti';
import { Icon } from '@mui/material';
import AvatarBadge from "./avatars/AvatarBadge";
import FlexBetween from "./flexbox/FlexBetween";
import Gallery from './Gallery';
import Links from './Links';
import { StyledFormControlLabel, StyledSelectInput } from "./StyledComponent";
import AppPagination from "./wrappers/AppPagination";

const godImagesMap = {
  "Ganesha": {"cover":"/ganesha-cover.png", "logo":"/ganesha-logo.png"},
  "Shiva": {"cover":"/shiva-cover.png", "logo":"/shiva-logo.png"},
  "Vishnu": {"cover":"/vishnu-cover.png", "logo":"/vishnu-logo.png"},
  "Skanda": {"cover":"/skanda-cover.png", "logo":"/skanda-logo.png"},
  "Shakti": {"cover":"/shakti-cover.png", "logo":"/shakti-logo.png"},
};

const godIcon = (fileName) => {
  console.log("creating icon from:" + fileName);
  return (
    <Icon sx={{height:'inherit', width:'inherit'}}>
        <img src={fileName} height={25} width={25}/>
    </Icon>
  )
}

const StyledTabList = styled(TabList)(({ theme }) => ({
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 50,
  [theme.breakpoints.up("sm")]: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
})); 

const StyledFlexBetween = styled(FlexBetween)(() => ({
  margin: "auto",
  flexWrap: "wrap",
}));

function ListItem({ title, Icon }) {
  return (
    <FlexBox alignItems="center">
      <Icon
        sx={{
          fontSize: 14,
          color: "text.secondary",
          mr: 1
        }}
      />
      <Tiny fontWeight={500} color="text.secondary">
        {title}
      </Tiny>
    </FlexBox>
  );
}

function Bhakta({place, source}) {
  console.log("Bhakta opened from:", source);
  console.log("Bhakta:", place);
  const [tabValue, setTabValue] = useState("1");
  const handleTabChange = (_, value) => setTabValue(value);
  const steps = [place.kalpa + ' Kalpa', place.manvantara + ' Manvantara', place.yugaCycle + ' of 71 Chatur Yugas', place.yuga + ' Yuga'];
  const smallCircleicon = () => <CircleIcon fontSize='small'/>;
  const ContentWrapper = styled(Box)(({ theme }) => ({
    zIndex: 1,
    marginTop: 55,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  }));
  const CoverPicWrapper = styled(Box)(({ theme }) => ({
    top: 0,
    left: 0,
    height: 125,
    width: "100%",
    overflow: "hidden",
    position: "absolute",
    backgroundColor: theme.palette.background.default,
  }));
  const ImageWrapper = styled(Box)(({ theme }) => ({
    width: 100,
    height: 100,
    margin: "auto",
    overflow: "hidden",
    borderRadius: "50%",
    border: "2px solid",
    borderColor: "black",
    paddingTop: "12px",
    backgroundColor: 'black',
  }));
  const [language, setLanguage] = React.useState(Object.keys(place.about)[0]);
  const [page, setPage] = React.useState(1);
  React.useEffect(() => {
    console.log("useEffect working in Bhakta.js");
    /*for (let prop in place.about) {
      setLanguage(prop);
      console.log("Language set in Bhakta.js:", prop);
      break;
    }*/
  }, []);
  //let lang = '';
  
  //const [language, setLanguage] = React.useState('english');
  const handleLanguageChange = (event) => {
    console.log("Language changed:", event);
    setLanguage(event.target.value);
  }
  const languages = [
    {name:"English", value:"english"}, {name:"हिंदी", value:"devanagari"}, {name:"தமிழ்", value:"tamil"}, {name:"తెలుగు", value:"telugu"}, {name:"ಕನ್ನಡ", value:"kannada"}
  ];
  const handlePageChange = (event, value) => {
    //console.log("Page changed in about Bhakta:", e);
    console.log("Navigating to Bhakta page:", value)
    setPage(value);
  };
  const generateTabs = (place, handleTabList) => {
    return (
        <TabContext value={tabValue}>
            <StyledTabList variant="scrollable" onChange={handleTabList}>
                <Tab disableRipple label="About" value="1" />
                <Tab disableRipple label="Gallery" value="2" />
                <Tab disableRipple label="Links" value="3" />
                <Tab disableRipple label="Misc" value="4" />
            </StyledTabList>
          <TabPanel value="1" sx={{pt:0, mt:0}}>
            <Stack alignItems="center" marginY={4}>
                <AppPagination
                  shape="rounded"
                  onChange={handlePageChange}
                  count={place.about[language].length}
                />
              </Stack>
              <Stack alignItems="center">
                <div style={{marginTop:25}} dangerouslySetInnerHTML={{__html:place.about[language][page-1]}}></div>
              </Stack>
          </TabPanel>
          <TabPanel value="2" sx={{pt:0, mt:0}}>
            <Gallery photos={place.photos}/>
          </TabPanel>
          <TabPanel value="3" sx={{pt:0}}>
            <Links savedLinks={place.links}></Links>
          </TabPanel>
          <TabPanel value="4" sx={{pt:0}}>
            <div>Misc section</div>
          </TabPanel>
        
      </TabContext>
    );
};
  return (
    <Card
        sx={{
          padding: 3,
          position: "relative",
        }}
      >
        <CoverPicWrapper>
          <img
            width="100%"
            height="100%"
            alt="Team Member"
            src={godImagesMap[place.god].cover}
            style={{
              objectFit: "cover",
            }}
          />
        </CoverPicWrapper>

        <ContentWrapper>
          <FlexBox justifyContent="center">
            <AvatarBadge>
              <ImageWrapper>
                <img
                  src={godImagesMap[place.god].logo}
                  alt="Team Member"
                  sizes="large"
                />
              </ImageWrapper>
            </AvatarBadge>
          </FlexBox>
        <Box mt={2}>
            <H4 fontWeight={600} textAlign="center">
              {place.name}
            </H4>

          </Box>
          </ContentWrapper>
          <StyledFlexBetween paddingTop={1} maxWidth={340}>
              <ListItem title={place.type} Icon={Person2Icon} />
              <ListItem title={place.city} Icon={LocationOnIcon} />
              <ListItem title={place.yuga + " Yuga"} Icon={ScheduleIcon} />
            </StyledFlexBetween>

            {false && <Stepper alternativeLabel sx={{marginTop:2}}>
              {steps.map((label, index) => {
               
                return (
                  <Step key={label}>
                    <StepLabel StepIconComponent={smallCircleicon}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>}
            
            
          {generateTabs(place, handleTabChange)}
          <FlexBox justifyContent="right">
              <Grid item xs={4}>
                <H6 mb={1}>Language</H6>
                    <Select
                      value={language}
                      //onChange={(e) => setLanguage(e.target.value)}
                      onChange={handleLanguageChange}
                      IconComponent={() => <KeyboardArrowDown />}
                      input={<StyledSelectInput />}
                    >
                    {languages.map(lang => (
                      <MenuItem
                      value={lang.value}
                      sx={{
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {lang.name}
                    </MenuItem>
                    )
                    )}
                      
                    </Select>
                  </Grid>
            </FlexBox>
      </Card>
      )
}

export default Bhakta;