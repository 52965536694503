import React from 'react';
import { useState } from "react";
import {connect} from 'react-redux';
import { Alert, Backdrop, Button, CircularProgress, Stack } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FlexBox from '../flexbox/FlexBox';
import Bhakta from '../Bhakta';
import { closeModal, uploadFile, deleteLinks, deleteFiles } from '../../actions';


const BhaktaPopup = ({place, showModal, user, cities, status, profile, closeModal, uploadFile, deleteLinks, deleteFiles}) => {
    console.log("Bhaktapopup rendered");
    const [editMode, setEditMode] = useState(false);
    const handleClose = () => {
        console.log("closing modal");
        deleteFiles();
        deleteLinks();
        closeModal();
    };
    
    return (
        status.showModal && <Dialog
        open={status.showModal}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        onClick={(e) => e.stopPropagation()}
        maxWidth="md"
      >
        <DialogContent dividers={true}>
          {!editMode && <div>
            <Bhakta place={status.bhakta} source="BhaktaPopup"/>
          </div>}
          
        </DialogContent>
        <DialogActions>
        <Stack
            direction="row"
            alignItems="center"
            width="100%"
            height="100%"
            spacing={1.5}>
              <FlexBox width="100%" height="100%">
              <div>
            </div>
              </FlexBox>
              <div>
                <Stack direction="row">
                <Button sx={{marginLeft:1}} onClick={handleClose} color="primary"  variant="outlined">Close</Button>
                </Stack>
          </div>
            </Stack>
          
          
          
        </DialogActions>
      </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
      cities: state.cities,
      status: state.status,
      profile: state.profile
    };
  };

export default connect(mapStateToProps, { closeModal, uploadFile, deleteLinks, deleteFiles})(BhaktaPopup);