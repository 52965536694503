
import {
  ADD_LINK,
  DELETE_LINK,
  SET_LINKS,
  DELETE_LINKS
} from '../actions/types';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case ADD_LINK:
        return [...state, action.payload];
      case DELETE_LINK:
        return state.filter(link => link[1] !=action.payload[1])
      case SET_LINKS:
        return action.payload;
      case DELETE_LINKS:
        return [];
      default:
        return state;
  }
};
