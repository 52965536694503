import React from 'react';
import { Avatar, Box, Grid, ListItem, ListItemIcon, ListItemText, ListItemAvatar } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { H1, H6, Small, Span, Tiny } from "./Typography";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const Objectives = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <React.Fragment>
            <Box pt={6}>

            </Box>
            <Box margin="auto" textAlign="center" sx={{ bgcolor: '#a84232'}} >
                
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="#fff"
                        indicatorColor="#fff"
                        aria-label="secondary tabs example"
                        variant="scrollable"
                        TabIndicatorProps={{style: {background:'#fff', color:'#fff'}}}
                        sx={{display:'flex', justifyContent:'flex-end'}}
                    >
                        <Tab value={0} label="Veda Patasha" sx={{color:'#fff'}}/>
                        <Tab value={1} label="Hindu Temples and Mutt" sx={{color:'#fff'}}/>
                        <Tab value={2} label="Goshala" sx={{color:'#fff'}}/>
                        <Tab value={3} label="Natural Resources" sx={{color:'#fff'}}/>
                        <Tab value={4} label="RamaKoti" sx={{color:'#fff'}}/>
                        <Tab value={5} label="Anna Daanam" sx={{color:'#fff'}}/>
                        <Tab value={6} label="Non Monetary Tasks" sx={{color:'#fff'}}/>
                    </Tabs>
                    <TabPanel value={value} index={0}><VedaPatashala/></TabPanel>
                    <TabPanel value={value} index={1}><TemplesAndMutt/></TabPanel>
                    <TabPanel value={value} index={2}><Goshala/></TabPanel>
                    <TabPanel value={value} index={3}><NaturalResources/></TabPanel>
                    <TabPanel value={value} index={4}><RamaKoti/></TabPanel>
                    <TabPanel value={value} index={5}><AnnaDaanam/></TabPanel>
                    <TabPanel value={value} index={6}><NonMonetary/></TabPanel>
                
            </Box>
                <Box pt={3}>

            </Box>
            
      
        </React.Fragment>
    )
}

const VedaPatashala = () => {
    return (
        <Box  mt={3} textAlign="center" maxWidth={1000} margin="auto" sx={{backgroundColor: '#faf8e3', borderRadius:5}}>
                <Small letterSpacing={2} fontWeight={800} color="#a84232">
                    Veda Patashala
                </Small>
                <Tiny color="text.secondary" fontSize={14} py={1} fontWeight={400}>
                    Veda Patashala is a sacred haven dedicated to teaching, promoting, and preserving the timeless wisdom of the Vedas, Agamas, and related scriptures. Our trust, rooted in a deep reverence for these ancient texts, strives to nurture and support individuals committed to imparting this profound knowledge. We have undertaken the construction, operation, and maintenance of centres, schools, and institutions focused on the study and dissemination of these scriptures. Our Veda Patashala is a sanctuary where the sacred vibrations of Vedic rituals and teachings resonate, fostering spiritual growth and understanding. We also extend a helping hand to financially disadvantaged students, offering scholarships that enable them to engage in the pursuit of Vedic knowledge.
                    Moreover, we actively publish and disseminate educational materials, spreading awareness through various mediums. At our Veda Patashala, we also honour and support scholars and individuals who have made significant contributions to Veda, Agama, and related disciplines, recognising their dedication and invaluable work in preserving this ancient wisdom. Join us on this transformative journey of learning, enlightenment, and spiritual evolution.
                </Tiny>
      </Box>
    );
}

const TemplesAndMutt = () => {
    return (
        <Box  textAlign="center" maxWidth={1100} margin="auto" sx={{backgroundColor: '#faf8e3', borderRadius:5}}>
            <Small letterSpacing={2} fontWeight={800} color="#a84232">
                Hindu Temples and Mutt
            </Small>
            <Tiny color="text.secondary" fontSize={14} py={2} fontWeight={400}>
            Our Trust is deeply committed to the preservation and promotion of the rich heritage of Hindu Temples and Mutts. We own, maintain, administer, and, when needed, refurbish or reconstruct existing Hindu Temples and Mutts, ensuring their sanctity is upheld. Moreover, we extend our support to the refurbishment, reconstruction, maintenance, and administration of temples and mutts not owned by the Trust but considered essential for the continuity of these sacred places, safeguarding our religion and the community's welfare. We dedicate ourselves to the proper functioning, upkeep, and maintenance of religious practices associated with these institutions, including conducting ceremonies, festivals, and rituals while providing essential facilities and services to devotees in accordance with the Hindu Way of Life. Our Trust also takes pride in organising and managing events and celebrations, such as Kumbhaabhishekam, at the temples and mutts under our ownership or management. Additionally, we contribute, both in cash and kind, towards the successful conduct, maintenance, and support of events and celebrations at other temples, mutts, and trusts that share similar objectives. 
            Join us in preserving and nurturing the spiritual sanctuaries that connect us to our profound cultural heritage. The below temples are under the purview of our Trust.
          </Tiny>
          <Box>
          <Small letterSpacing={1} fontWeight={800} color="#a84232">1. Shri Shivagami Sametha Prathapa Simheshwara Swamy Devasthanam</Small>
          <Small color="text.secondary" fontSize={14} py={0} fontWeight={400}>: Nestled in serene backdrop of Setur, Puducherry is this ancient temple of Shiva, Parvathi. 
              Current active projects include completing the compound wall around the temple and building a 3 tier 36 feet tall Rajagopuram. Subsequent Kumbabhishekam after Rajagopuram completion is planned for Q1 of next year at the dawn of Uttarayana punya kaalam.
          </Small>
          </Box>
          <Box>
          <Small letterSpacing={1} fontWeight={800} color="#a84232">2. Kamakshi Amman Kovil</Small>
          <Small color="text.secondary" fontSize={14} py={0} fontWeight={400}>: Located within the Shri Shivagami Sametha Prathapa Simheshwara Swamy devasthanam complex is this temple ok Kamakshi
              Amman. The idol was brought to this place by Maha Periyava when he was a Bala Periyava. 
              Current active projects include installing a Rajagopuram.</Small>
              </Box>
          <Box>
          <Small letterSpacing={1} fontWeight={800} color="#a84232">3. Shri Lakshmi Perumal Kovil, Setur</Small>
          <Small color="text.secondary" fontSize={14} py={0} fontWeight={400}>: No active projects at the moment.</Small>
            </Box>
          <Box>
          <Small letterSpacing={1} fontWeight={800} color="#a84232">4. Shri Poorna Pushkalamba sametha dharma shastha, Perambur</Small>
          <Small color="text.secondary" fontSize={14} py={0} fontWeight={400}>: No active projects at the moment. </Small>
            </Box>
          <Box>
          <Small letterSpacing={1} fontWeight={800} color="#a84232">5. Shridevi Bhoodevi sametha Varadaraja Swamy Kovil, Perambur</Small>
          <Small color="text.secondary" fontSize={14} py={0} fontWeight={400}>: No active projects at the moment. </Small>
            </Box>
          <Box>
          <Small letterSpacing={1} fontWeight={800} color="#a84232">6. Shri Akhilandeshwari sametha Jambukeshwara Swamy Kovil, Perambur</Small>
          <Small color="text.secondary" fontSize={14} py={0} fontWeight={400}>: No active projects at the moment. </Small>
            </Box>
      </Box>
    );
}

const Goshala = () => {
    return (
        <Box  textAlign="center" maxWidth={800} margin="auto" sx={{backgroundColor: '#faf8e3', borderRadius:5}}>
            <Small letterSpacing={2} fontWeight={800} color="#a84232">
                Goshala
            </Small>
            <Tiny color="text.secondary" fontSize={14} py={2} fontWeight={400}>
            Our Goshala stands as a sanctuary for the revered cows, embodying our commitment to their protection, welfare, and preservation in alignment with the principles of the Hindu Way of Life. We have established and diligently maintain Goshalas, providing these gentle creatures with a haven of care and compassion. Our dedicated efforts include ensuring their proper care, access to medical treatment, and the promotion of ethical and sustainable practices for cow conservation. At our Goshala, we uphold a sacred promise that the cows under our care are never traded, sold, given away, or exploited, contrary to the Hindu way of life. The produce, including milk and Go Muthram, from our Goshala serves as a sacred offering for religious activities, and any surplus is thoughtfully sold to support the broader activities of our Trust. To further bolster the welfare of our bovine companions, we cultivate and maintain the crops necessary for producing nutritious fodder, ensuring their well-being is upheld with the utmost reverence. Join us in this noble endeavour of cow protection and preservation.
            </Tiny>
      </Box>
    );
}

const NaturalResources = () => {
    return (
        <Box  textAlign="center" maxWidth={800} margin="auto" sx={{backgroundColor: '#faf8e3', borderRadius:5}}>
            <Small letterSpacing={2} fontWeight={800} color="#a84232">
                Natural Resources
            </Small>
            <Tiny color="text.secondary" fontSize={14} py={2} fontWeight={400}>
            Our Trust is firmly committed to the preservation and protection of our invaluable natural resources and the environment. We take a proactive stance in contributing, both directly and indirectly, in cash or kind, towards safeguarding, maintaining, and preserving these vital assets. Our efforts encompass a wide spectrum of activities, including the plantation and meticulous care of trees, the construction and upkeep of essential infrastructure such as ghats and mandapams, and the maintenance of canals, lakes, rivers, and associated infrastructure, ensuring access and safety for all. We are dedicated to responsible waste management, including waste removal, sewage and sewerage management, and sullage removal, promoting cleanliness and sustainability. Additionally, we install and maintain manual or automated irrigation systems to ensure the efficient use of water resources. Our commitment to the preservation and protection of nature and natural resources underscores our belief in a harmonious coexistence with the environment. Join us in these endeavours to create a greener and more sustainable future for all.
            </Tiny>
      </Box>
    );
}

const RamaKoti = () => {
    return (
        <Box  textAlign="center" maxWidth={800} margin="auto" sx={{backgroundColor: '#faf8e3', borderRadius:5}}>
            <Small letterSpacing={2} fontWeight={800} color="#a84232">
                RamaKoti
            </Small>
            <Tiny color="text.secondary" fontSize={14} py={2} fontWeight={400}>
            Our Trust is dedicated to the propagation and promotion of Rama Nama Japam through the RamaKoti initiative. This noble endeavour encompasses various activities to encourage individuals to recite and write the divine name of Rama. We print and distribute RamaKoti writing books to those interested in this sacred practice and actively encourage them to engage in the recitation and writing of Rama Namam. We collect and maintain a stock of RamaKoti books, both those that have been recollected after completion and those yet to be distributed. These books, once filled with the divine name, are used for relevant rituals and purposes. To support this mission, we may construct, acquire, or lease property space dedicated to storing Rama Nama Books, ensuring that this sacred tradition continues to thrive and inspire spiritual devotion. Join us in the profound journey of Rama Nama Japam and experience the transformative power of this divine practice.
            </Tiny>
      </Box>
    );
}

const AnnaDaanam = () => {
    return (
        <Box  textAlign="center" maxWidth={800} margin="auto" sx={{backgroundColor: '#faf8e3', borderRadius:5}}>
            <Small letterSpacing={2} fontWeight={800} color="#a84232">
                Anna Daanam & Under Priviledged Support
            </Small>
            <Tiny color="text.secondary" fontSize={14} py={2} fontWeight={400}>
                We strive to stand behind those in need. One of our initiatives is to provide free food for the under privilidged people starting
                in the year 2025. Also in 2025, we are going to reach out to local government school in Perambur to assess the needs of the school and
                start working for those causes under our Trust core principles.
            </Tiny>
      </Box>
    );
}

const NonMonetary = () => {
    return (
        <Box  textAlign="center" maxWidth={800} margin="auto" sx={{backgroundColor: '#faf8e3', borderRadius:5}}>
            <Small letterSpacing={2} fontWeight={800} color="#a84232">
                Non Monetary Tasks
            </Small>
            <Tiny color="text.secondary" fontSize={14} py={2} fontWeight={400}>
                Guruji initiated several non-monetary activities to revive our spirituality individually. None of these require any monetary
                contribution and can be done remotely as per the individual's choice. Participation is open to all. Please contact us for any questions
                <Box>
                1. Daily Rama Nama Japam (from 4 am to 5 am IST)
                </Box>
                <Box>
                2. Daily Vishnu Sahasra Namam recitation
                </Box>
                <Box>
                3. Daily Chandrashekhara Ashtottaram recitation
                </Box>
                <Box>
                4. Daily bodhendra Ashtottaram recitation
                </Box>
                <Box>
                5. Daily Rama Namam writing
                </Box>
                <Box>
                6. Ashtottaram writing
                </Box>
                <Box>
                7. Bhakta story writing (these will eventually make it to Chaithanya Kuteera Prakashaha)
                </Box>
            </Tiny>
      </Box>
    );
}

export default Objectives;