import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const Footer = () => {
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = React.useState(false);
    const handlePrivacyPolicyClose = () => {
        setPrivacyPolicyOpen(false);
      };
    const handlePrivacyPolicyOpen = () => {
        setPrivacyPolicyOpen(true);
      };
    const [termsOpen, setTermsOpen] = React.useState(false);
    const handleTermsClose = () => {
        setTermsOpen(false);
      };
    const handleTermsOpen = () => {
        setTermsOpen(true);
      };
    return (
        <React.Fragment x={{ flexGrow: 1, justifyContent: 'space-between' }}>
            <Box sx={{ display: {  backgroundColor: '#db622b' } }}>
                <Button key="copywright" sx={{ color: '#fff' }}>
                    Copyright 2023
                </Button>
                <Button  sx={{ color: '#fff' }}  onClick={handlePrivacyPolicyOpen}>
                    Privacy Policy
                </Button>
                <Button key="terms" sx={{ color: '#fff' }} onClick={handleTermsOpen}>
                    Terms and Conditions
                </Button>
            </Box>
            <Dialog
                onClose={handlePrivacyPolicyClose}
                aria-labelledby="customized-dialog-title"
                open={privacyPolicyOpen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Privacy Policy
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleTermsClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                    We are committed to protecting your privacy. This privacy policy sets out how we collect, use, and protect any personal information that you provide to us when you use our website and donate to our organisation.

We may update this privacy policy from time to time by posting a new version on our website. You should check this page occasionally to ensure you are happy with any changes.

What information do we collect?

We may collect the following information from you when you use our website and donate to our organisation:

Your name
Your email address
Your mailing address
Your telephone number
Your ID (PAN, Aadhaar or any Government ID)
Your credit card or other payment information
How do we use this information?


We use the information we collect from you to:

Process and acknowledge your donation
Provide you with information about our organisation, our programs, and our events
Improve our website and our organisation’s operations
Comply with applicable laws and regulations

How do we protect your information?

We are committed to ensuring that your personal information is secure. To prevent unauthorised access or disclosure, we have established suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online.

Who do we share your information with?

We do not sell, rent, or share your personal information with third parties for their marketing purposes.

We may share your information with third-party service providers who assist us in operating our website and processing donations. These service providers are contractually obligated to use the information we provide only for the purposes of providing services to us and to maintain the confidentiality and security of your personal information.

We may also disclose your personal information if required to do so by law or in the good-faith belief that such action is necessary to comply with applicable laws or respond to a court order, subpoena, or search warrant.

Your rights

You have the right to:
Access the personal information we hold about you
Request that we correct or delete your personal information
Object to the processing of your personal information
Request that we restrict the processing of your personal information
Withdraw your consent to the processing of your personal information
To exercise these rights, please contact us using the information below.

Contact us

If you have any questions or concerns about this privacy policy, please contact us at
contact@ShriChandrashekharaBodhendraSaraswathiTrust.org

By using our website and/or donating to our organisation, you consent to the collection and use of your personal information as described in this privacy policy.

                    </Typography>
                    
                </DialogContent>
            </Dialog>

            <Dialog
                onClose={handleTermsClose}
                aria-labelledby="customized-dialog-title"
                open={termsOpen}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Terms and Conditions
        </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handlePrivacyPolicyClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                    Welcome to our website. By continuing to browse and use this website, you agree to comply with and be bound by the following terms and conditions of use, which, together with our privacy policy, govern our relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website.

The term ‘us’, ‘we’ and ‘our’ refers to the website's owner, Shri Chandrashekhara Bodhendra Saraswathi Trust, a non-profit organisation. The term ‘you’ refers to the user or viewer of our website.

In the case of donating to our organisation through our website, you are agreeing to the following terms and conditions.

All donations made through our website are final and non-refundable.

We will use all donations received through our website for the registered objectives outlined in our registered Trust Deed, the sole purpose for which our organisation is established. We reserve the right to allocate general donations to specific programs and initiatives within our organisation’s objectives at our discretion unless the donation is specifically mentioned to be allocated by the donor to one of the registered objectives.

We will issue a receipt for your donation to the email address you provide when making your donation within 24- 48 hours. Please ensure that the email address you provide is accurate.
By providing your personal information to us when donating through our website, you agree to our privacy policy.

We use a third-party payment processor to process donations made through our website. We do not store or have access to your credit card or other payment information. Your use of the payment processor’s services is subject to their terms and conditions.
We reserve the right to refuse any donation or to cancel a donation previously accepted by us for any reason, including but not limited to suspected fraudulent activity.

Donations made through our website may be tax-deductible in accordance with applicable tax laws viz Under Section 80(G) of Indian Income Tax Laws. Please consult your tax advisor for advice regarding the tax deductibility of your donation.

We make no representations or warranties regarding the tax deductibility of your donation. It is your responsibility to retain documentation of your donation for tax purposes.

We reserve the right to modify or update these terms and conditions without notice. It is your responsibility to check this page periodically for any changes. Your continued use of this website following the posting of changes will mean that you accept and agree to the changes.

Your use of this website and any dispute arising out of such use of the website is subject to the laws of the state of Tamil Nadu, India.

          </Typography>
                    
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}

export default Footer;