import React from 'react';
import { NewReleases } from "@mui/icons-material";
import { Avatar, Box, Grid, ListItem, ListItemIcon, ListItemText, ListItemAvatar } from "@mui/material";
import { H1, H6, Small, Span, Tiny } from "./Typography";


const About = () => {
    const data = [
        {
          primary: "श्री चन्द्रशेखर सरस्वती",
          secondary: "अपार करुणा सिन्धुम् ज्ञानतम शांत रूपिणम्, श्री चन्द्रशेखर गुरूम् प्रणमामि मुथन्वाहम्.",
          image: "maha-periyava.png"
        },
        {
          primary: "श्री बोधेन्द्र सरस्वती",
          secondary: "भगवान नाम साम्राज्य लक्ष्मी सर्वस्व विग्रहम्, श्रीमद् बोधेन्द्र योगीन्द्र देशिकेन्द्र मुपस्महे",
          image: "bodhendra.png"
        },
        {
          primary: "श्री चन्द्रशेखर भारती",
          secondary: "सदात्मा ध्यान निर्मितम् विषयेभ्यव परान्मुखम् नौमि शास्त्रेषु निष्णातम चन्द्र शेखर भारतीम्",
          image: "chandrashekhara-bharathi.png"
        }
      ];
    return (
        <Grid spacing={3}>
            <Box pt={6}>

            </Box>
            
            <Box  textAlign="center" maxWidth={1000} margin="auto" sx={{backgroundColor: '#faf8e3', borderRadius:5}}>
            <Box pt={6}>
        <Grid container spacing={3}>
          {data.map((item, index) => (
            <Grid item md={4} key={index}>
              <ListItem
                sx={{
                  alignItems: "flex-start",
                }}
              >
                <ListItemAvatar
                  sx={{
                    padding: 1,
                    borderRadius: 1,
                    backgroundColor: "#db622b",
                    marginRight:1
                  }}
                >
                  {false && <NewReleases
                    sx={{
                      color: "common.white",
                    }}
                  />}
                  <Avatar alt="Maha Periyava" src={item.image} />
                </ListItemAvatar>
                <ListItemText
                  primary={item.primary}
                  secondary={item.secondary}
                  sx={{
                    marginY: 0,
                    "& .MuiListItemText-primary": {
                      fontSize: 16,
                      color: "text.primary",
                      fontWeight: 600,
                      lineHeight: 1,
                    },
                    "& .MuiListItemText-secondary": {
                      color: "text.primary",
                      fontWeight: 500,
                      paddingTop: 1,
                    },
                  }}
                />
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box textAlign="center" maxWidth="90%" margin="auto">
        <Tiny color="text.secondary" fontSize={14} py={2} fontWeight={400}>
        Guru provides direction and  <Span color="#a84232">Marga darshakam.</Span> They live for others and teach us to live for others (
          <Span color="#a84232">Jana Janma Uddara Kakshintacha</Span>). They Always provide Prakasha(radiance) all around to do good. They are strong followers of Dharma, show how to perform Dharma, live life in Dharmic Style. <Span color="#a84232">Dharmo Rakshati, Rakshitaha.</Span> Any activity without Guru initiation goes in vain - <Span color="#a84232">Guru Parethe Sadana Nahin.</Span> 
             Guru needs to be at the forefront of any activity we do. Every step taken needs to follow Guru's teachings to ultimately complete Samarpanam to Guru!! 
          <Span color="#a84232">Guru Charana Vindyabhya namaha.</Span>
          </Tiny>
      </Box>
        <Small letterSpacing={2} fontWeight={800} color="#a84232">
        OUR MISSION
        </Small>

        <H6 color="text.secondary" fontSize={14} py={0} fontWeight={400} paddingBottom={2}>
        <Span color="#a84232">Shri Chandrashekharendra Bodhendra Saraswathi Trust</Span> was formed under Guruji's direction to serve it’s prime objectives, including providing a sustainable Vedic education to Veda Vidhyarthis under the traditional oral format and working towards other objectives for reviving Vedic culture, tradition and lifestyle.

        
          </H6>
      </Box>
        </Grid>
        
    );
};

export default About;