
import {
    CREATE_PLACE_ERROR,
    CREATE_PLACE_SUCCESS,
    FETCH_PLACES_ERROR,
    FETCH_PLACES_SUCCESS,
    FETCH_BHAKTA_SUCCESS,
    LOAD_STATUS,
    CLOSE_MODAL,
    OPEN_MODAL,
    RESET_ERROR
  } from '../actions/types';
  
  const INITIAL_STATE = {showModal: false};
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_PLACE_SUCCESS:
        return {...state, error: null, loading: false, result: 'CREATE_PLACE_SUCCESS'};
        case CREATE_PLACE_ERROR:
        return {...state, error: action.payload, loading: false, result: 'CREATE_PLACE_ERROR'};
        case FETCH_PLACES_SUCCESS:
        return {...state, error: null, loading: false, result: 'FETCH_PLACES_SUCCESS',};
        case FETCH_PLACES_ERROR:
        return {...state, error: action.payload, loading: false, result: 'FETCH_PLACES_ERROR'};
        case FETCH_BHAKTA_SUCCESS:
        return {...state, error: null, loading: false, result: 'FETCH_BHAKTA_SUCCESS'};
        case LOAD_STATUS:
        return {...state, error: null, loading:action.payload};
        case CLOSE_MODAL:
        return {...state, showModal: false, bhakta: null};
        case OPEN_MODAL:
        return {...state, showModal: true, bhakta: action.payload};
        case RESET_ERROR:
        return {...state, error: null};
      default:
        return state;
    }
  };
  