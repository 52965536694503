import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Body from './components/Body';
import Footer from './components/Footer';

function App() {

  const theme = createTheme({
    typography: {
      button: {
        textTransform: 'none',
        fontFamily:'Montserrat, sans-serif'
      },
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Sans Devanagari"'
      ].join(','),
    },
    palette: {
      primary: {
        //main: orange[500],
        main: '#db622b',
        darker: '#053e85',
      },
      neutral: {
        main: '#64748B',
        contrastText: '#fff',
      }
    },
    components: {
      MuiButtonss: {
        styleOverrides: {
          outlined: {
            backgroundColor: '#64748B'
          },
          contained:{
            //backgroundColor: orange[500]
            backgroundColor: '#db622b'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          outlined: {
           //backgroundImage: 'linear-gradient(to top, #d299c2 0%, #fef9d7 100%)',
           //backgroundImage: orange[500]
           //color: 'green',
           //backgroundColor: orange[500],
           //color: orange[500],
           color: '#db622b',
           //backgroundColor: '#202020',
          },
          containedPrimary: {
            //backgroundImage: 'linear-gradient(to top, #d299c2 0%, #fef9d7 100%)',
            //backgroundImage: orange[500]
            //color: 'green',
            //backgroundColor: orange[500],
            //backgroundColor: '#db622b',
            backgroundColor: '#db622b'
           },
           contained:{
            //backgroundColor: orange[500]
            //backgroundColor: '#db622b'
            backgroundColor: '#db622b'
          },
          text: {
           color: "red"
          }
        },
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
      <div className="App">
      <Header></Header>
      <Body></Body>
      <Footer></Footer>
    </div>
    </Router>
    </ThemeProvider>
    
    
  );
}

export default App;
