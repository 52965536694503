
import {
  UPLOAD_FILE,
  DELETE_FILES,
  SET_FILES
} from '../actions/types';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case UPLOAD_FILE:
        return [...state, action.payload];
      case SET_FILES:
        return action.payload;
      case DELETE_FILES:
        return action.payload;
    default:
      return state;
  }
};
