
import {
    FETCH_PROFILE,
    SET_PROFILE
  } from '../actions/types';
  
  const INITIAL_STATE = {};
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PROFILE:
        return {...state, allowEdit: action.payload.allowEdit, userName: action.payload.userName, userDetails: action.payload.userDetails};
        case FETCH_PROFILE:
        return {...state, allowEdit: action.payload.allowEdit, userName: action.payload.userName};
      default:
        return state;
    }
  };
  