import React from 'react';
import { Field, FormSection, getFormValues, reduxForm } from 'redux-form';
import {
    Button,
    Checkbox,
    Divider,
    Drawer,
    FormControl,
    FormControlLabel,
    FormGroup,
    Rating,
    Stack,
    styled,
  } from "@mui/material";
  import { Box } from "@mui/system";
  import AppCheckBox from "../components/input-fields/AppCheckBox";
  import AppTextField from "../components/input-fields/AppTextField";
  import Restore from "../icons/Restore";
  import { H3, H5 } from "../components/Typography";

  const StyledFormControlLabel = styled(FormControlLabel)(() => ({
    "& .MuiCheckbox-root": {
      padding: "5px 9px",
    },
    "& .MuiRadio-root": {
      paddingTop: 5,
      paddingBottom: 5,
    },
    "& .MuiTypography-root": {
      fontSize: 12,
      fontWeight: 500,
    },
  })); 

function FilterForm({handleSubmit}) {

    const handleFilterUpdate = () => {
        //const formValues = useSelector(state => getFormValues('filterForm')(state))
        //console.log("filter data:", filterData);
        //console.log("VIEW data:", view);
        //console.log("form from props:", form);
      };
    
      const renderCheckbox = ({input, label, checked}) => (
        <StyledFormControlLabel
                  value={input.value}
                  control={<AppCheckBox />}
                  label={label}
                  onChange={input.onChange}
                />
      )

      const renderTextBox = ({ input, label, initialValues, meta: { touched, error }  }) => (
        <div>
        
          <AppTextField
                          
                          fullWidth
                          name={input.name}
                          label={label}
                          value={input.value}
                          onChange={input.onChange}
                          disabled={initialValues && initialValues.name}
                          variant="outlined"
                          onBlur={input.onBlur}
                          error={touched && error}
                          helperText={touched && error}
                        />
        </div>
      )

      const GodFilter = () => {
        return (
          <Box>
            <H5 mb={2}>God</H5>
            <FormSection name="god">
              <FormGroup>
                  <FormControl>
                    <Field name="ganesha" component={renderCheckbox} label="Ganesha" id="ganesha"></Field>
                  </FormControl>
                  <FormControl>
                    <Field name="shiva" component={renderCheckbox} label="Shiva" id="shiva"></Field>
                  </FormControl>
                  <FormControl>
                    <Field name="vishnu" component={renderCheckbox} label="Vishnu" id="vishnu"></Field>
                  </FormControl>
                  <FormControl>
                    <Field name="skanda" component={renderCheckbox} label="Skanda" id="skanda"></Field>
                  </FormControl>
                  <FormControl>
                    <Field name="shakti" component={renderCheckbox} label="Shakti" id="shakti"></Field>
                  </FormControl>
                </FormGroup>
            </FormSection>
          </Box>
        );
      }
    
      const TypeFilter = () => {
        return (
          <Box>
            <H5 mt={3} mb={2}>
                Type
              </H5>
              <FormSection name="type">
                <FormGroup>
                  <FormControl>
                    <Field name="paramatma" component={renderCheckbox} label="Paramatma" id="paramatma"></Field>
                  </FormControl>
                  <FormControl>
                    <Field name="bhakta" component={renderCheckbox} label="Bhakta" id="bhakta"></Field>
                  </FormControl>
                  <FormControl>
                    <Field name="alwar" component={renderCheckbox} label="Alwar" id="alwar"></Field>
                  </FormControl>
                  <FormControl>
                    <Field name="nayanar" component={renderCheckbox} label="Nayanar" id="nayanar"></Field>
                  </FormControl>
                  <FormControl>
                    <Field name="sati" component={renderCheckbox} label="Sati" id="sati"></Field>
                  </FormControl>
                </FormGroup>
              </FormSection>
          </Box>
        );
      }
    
      const YugaFilter = () => {
        return (
          <Box>
            <H5 mt={3} mb={2}>
                Yuga
              </H5>
              <FormSection name="yuga">
              <FormGroup>
                <FormControl>
                  <Field name="satya" component={renderCheckbox} label="Satya" id="satya"></Field>
                </FormControl>
                <FormControl>
                  <Field name="treta" component={renderCheckbox} label="Treta" id="treta"></Field>
                </FormControl>
                <FormControl>
                  <Field name="dwapara" component={renderCheckbox} label="Dwapara" id="dwapara"></Field>
                </FormControl>
                <FormControl>
                  <Field name="kali" component={renderCheckbox} label="Kali" id="kali"></Field>
                </FormControl>
              </FormGroup>
              </FormSection>
          </Box>
        );
      }

      return (
          <form onSubmit={handleSubmit}>
              <Box padding={2} mt={1}>
          <Stack spacing={2}>
            <GodFilter/>
              <TypeFilter/>
              <YugaFilter/>
              <FormControl fullWidth>
                    <Field name="city" component={renderTextBox} label="City"/>
                </FormControl>
              <Button
                fullWidth
                variant="GreyOutlined"
                startIcon={<Restore />}
                sx={{
                  mt: 3,
                }}
              >
                Clear all
              </Button>
            </Stack>
            
        </Box>
          </form>
      );

}

export default reduxForm({
    form: 'filterForm',  // a unique identifier for this form     
    //touchOnChange: true,       
  })(FilterForm);