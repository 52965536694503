import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import placesReducer from './placesReducer';
import citiesReducer from './citiesReducer';
import filesReducer from './filesReducer';
import statusReducer from './statusReducer';
import linksReducer from './linksReducer';
import profileReducer from './profileReducer';
import viewReducer from './viewReducer';
import bhaktaReducer from './bhaktaReducer';
import filterReducer from './filterReducer';

export default combineReducers({
  form: formReducer,
  places: placesReducer,
  cities: citiesReducer,
  files: filesReducer,
  status: statusReducer,
  links: linksReducer,
  profile: profileReducer,
  view: viewReducer,
  bhakta: bhaktaReducer,
  filter: filterReducer
});
